.button-back {
    margin: 0px;
    padding: 0px;
    border: 0;
    position: relative;
    align-items: center;
    background: transparent;
    margin-left: -8px;

    i {
        color: #ffffff;
        font-size: 26px;
    }
}

.button-back:focus {
    outline: 0;
}

.button-back img {
    max-width: 32px;
    pointer-events: none;
}