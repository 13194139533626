$main-color: #00C7E7;
$secondary-color: #1C1D2E;
$featured-color: #FB8500;
$warning-color: #EF233C;
$success-color: #04D973;
$white-color: #ffffff;

$text-color: #777782;
$background-color: #EDEEEE;

$gray-color-1: #EDEEEE;
$gray-color-2: #D4D6D7;

/* Fonts */
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700&display=swap');

/* 400 = regular / 500 = medimum / 600 = semibold / 700 = bold */

@font-face {
  font-family: 'sounditi-client';
  src: url('../assets/fonts/fontello/font/sounditi-client.eot?92458470');
  src: url('../assets/fonts/fontello/font/sounditi-client.eot?92458470#iefix') format('embedded-opentype'),
       url('../assets/fonts/fontello/font/sounditi-client.woff?92458470') format('woff'),
       url('../assets/fonts/fontello/font/sounditi-client.ttf?92458470') format('truetype'),
       url('../assets/fonts/fontello/font/sounditi-client.svg?92458470#sounditi-client') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon {
  font-family: "sounditi-client";
  font-style: normal;
  font-weight: normal;
  speak: none;
  text-decoration: inherit;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #7f7d86;
  font-size: 16px;
}

/* General */
html {
  font-size: 6px;
  background: #000000;
  overflow: hidden;
  position: fixed;
  height: 100%;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

body {
  margin: 0;
  font-family: "Montserrat", "Helvetica Neue", sans-serif;
  color: #ffffff;
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  text-align: center;
  overflow: hidden;
  height: 100%;
}

#root {
  height: 100%;
}

h1 {
  font-size: 18px;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 3rem;
  margin-left: auto;
  margin-right: auto;
  line-height: 24px;
  margin-left: auto;
  margin-right: auto;
  white-space: pre-line;
}

p {
  margin-top: 0px;
  margin-bottom: 6rem;
  margin-left: auto;
  margin-right: auto;
  white-space: pre-line;
}

.version {
  position: fixed;
  bottom: 5px;
  left: 10px;
  font-size: 12px;
  opacity: 0;
}

span.more-large {
  font-size: 16px;
}

button {
  margin: 0px;
  padding: 0px;
  border: 0;
  position: relative;
  align-items: center;
  background: transparent;
  font-family: inherit;
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
  overflow: hidden;
}

button:focus {
  outline: 0;
}

button:hover {
  cursor: pointer;
}

button div,
button img,
button p {
  pointer-events: none;
}

.input.invalid {
  .input-row {
    .item {
      border-color: #EE3636;
    }
  }
}

.input-row {
  display: flex;
  // margin-left: -1rem;
  // margin-right: -1rem;
  border: 0px #EDEEEE solid;

  .input {
    margin: 0 1rem;
    width: 100%;
  }

  .item {
    text-align: center;
    display: flex;
    align-items: center;
    border: 1px #EDEEEE solid;
    margin-left: -1px;
    background-color: $white-color;

    p {
      margin: 0 auto;
      line-height: 46px;
      color: #1C1D2E !important;
      font-family: inherit;
      font-size: 13px;
      font-weight: 500;
    }
  }

  .item:first-child {
    margin-left: 0;
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
  }

  .item:last-child {
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
  }

  .item.active {
    // background: #ffffff;
    position: relative;
    z-index: 2;
    border-color: #00CDEE;
  }

  .item.active,
  .item:hover {
    p {
      color: #00CDEE;
    }
    cursor: pointer;
  }
}

.input-row.select-item {
  div {
    flex: 1;
  }
}

.float-top {
  margin-top: 10rem;
  z-index: 1;
}

.float-top h1 {
  max-width: 250px;
  margin-top: 2rem;
}

.center {
  margin: auto;
}

.no-margin-bottom {
  margin-bottom: 2rem;
}

.screen.swiper-container {
  padding: 0;
  align-items: center;
}

.swiper-slide {
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  padding-bottom: 11rem;
  box-sizing: border-box;
  flex-flow: column-reverse;
}

.swiper-slide div {
  display: flex;
  flex-direction: column;
  flex-flow: column-reverse;
}

.swiper-pagination-bullet {
  background: #ffffff;
  transition: all 0.2s ease-in-out;
}

.swiper-pagination-bullet-active {
  opacity: 1;
}

.swiper-container-horizontal>.swiper-pagination-bullets {
  bottom: 24px;
}

.swiper-pagination-bullet-active {
  width: 16px;
  border-radius: 4px;
}

.parallax-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
}

.screen {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff;
  color: #1C1D2E;
  /* background: #121212 url('/assets/background.png') center center; */
  background-size: cover;
  /* padding: 0 24px; */
  display: flex;
  flex-direction: column;
  /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3); */
  z-index: 0;
  overflow: auto;
  transform: translateX(0%);
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 6rem;

  .fixed-bottom p {
    max-width: 415px;
    white-space: pre-line;
  }
}

.parallax-bg {
  display: none;
}

.desktop-cover {
  position: absolute;
  width: calc(100% - 500px);
  top: 0;
  bottom: 0;
  right: 0;
  left: 500px;
  background: black;
  z-index: 2;
  display: block;
  /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3); */
  transition: width 350ms ease-in-out;
  align-content: center;
  align-items: center;
  overflow: hidden;

  /*
  width: calc(50% - 250px);
  left: calc(50% + 250px);
  */

  .logo-byalyze {
    width: auto;
    position: absolute;
    bottom: 5rem;
    right: 5rem;
    left: auto;
    height: auto;
    top: auto;
    z-index: 9;
    background: black;
    padding: 1rem 2rem;
    transform: none;
    font-weight: 500;

    p {
      overflow: hidden;
      white-space: nowrap;
      margin: 0;
      font-size: 12px;

      a {
        color: #ffffff;
        text-decoration: none;
      }
    }

    img {
      width: 100%;
    }
  }
}


/*
.bg-parallax {
  background: #121212 url('/assets/bg-woman-parallax.jpg') center center;
  background-size: cover;
}

.bg-parallax-mesh {
  background: url('/assets/bg-woman-parallax-mesh.png') center center;
  background-size: cover;
}
*/

.desktop-cover div {
  width: 110%;
  height: 110%;
  position: absolute;
  top: -5%;
  left: -5%;
  transform: translate(-50%, -50%);
}

.backgroud-filter {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.6;

  /*
  background: linear-gradient(-45deg, #EE7752, #E73C7E, #23A6D5, #23D5AB);
  */
	background-size: 400% 400%;
	-webkit-animation: Opacity 15s ease infinite;
	-moz-animation: Opacity 15s ease infinite;
	animation: Opacity 15s ease infinite;
}

@-webkit-keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

@-moz-keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

@keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

.logo-text {
  font-size: 22px;
  font-weight: 600;
}

.logo-big {
  max-width: 90px;
  margin: auto;
}

.logo-small {
  max-width: 90px;
  margin: auto;
}

.social-buttons-separator {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.tag-black {
  background: #000000;
  color: #ffffff;
  font-weight: 600;
  padding: 2px 6px;
}

input[type=number] {
  /* -webkit-text-security: disc; */
}

.message-black {
  font-weight: 600;
  color: white;
  background: #000000;
  padding: 1rem;
  margin-bottom: 0;
}

.camera-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 3;
}

.camera-fullscreen {
  /*
  position: absolute;
  box-sizing: content-box;
  width: auto;
  height: auto;
  left: 50%;
  top: 50%;
  -o-transform: translate(-50%, -50%) rotateY(180deg);
  -ms-transform: translate(-50%, -50%) rotateY(180deg);
  -moz-transform: translate(-50%, -50%) rotateY(180deg);
  -webkit-transform: translate(-50%, -50%) rotateY(180deg);
  transform: translate(-50%, -50%) rotateY(180deg);
  z-index: 0;
  */
  position: fixed;
  visibility: hidden;
}

.camera-calibration .camera-fullscreen {
  position: absolute;
  box-sizing: content-box;
  width: auto;
  height: auto;
  left: 50%;
  top: 50%;
  -o-transform: translate(-50%, -50%) rotateY(180deg);
  -ms-transform: translate(-50%, -50%) rotateY(180deg);
  -moz-transform: translate(-50%, -50%) rotateY(180deg);
  -webkit-transform: translate(-50%, -50%) rotateY(180deg);
  transform: translate(-50%, -50%) rotateY(180deg);
  z-index: 0;
  visibility: visible;
}

.experience .camera-fullscreen {
  /* filter: grayscale(100%) blur(5px); */
}

.camera-fullscreen.landscape {
  height: 100%;
}

.camera-fullscreen.portrait {
  width: 100%;
}

.black-and-white {
  filter: grayscale(100%);
}

.camera-bottom {
  background: #000000;
  padding: 5rem 4rem;
}

.camera-bottom p {
  max-width: 230px;
  margin: auto;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}

.analizing-face .camera-bottom p {
  max-width: 305px;
}

.vertical-center {
  flex: 1;
  display: flex;
}

.overlay {
  z-index: 99;
  width: 1280px;
  height: 720px;
  position: absolute;
  border: 0px red solid;
  left: 50%;
  top: 50%;
  -o-transform: translate(-50%, -50%) rotateY(180deg);
  -ms-transform: translate(-50%, -50%) rotateY(180deg);
  -moz-transform: translate(-50%, -50%) rotateY(180deg);
  -webkit-transform: translate(-50%, -50%) rotateY(180deg);
  transform: translate(-50%, -50%) rotateY(180deg);
}

.overlay.picture {
  z-index: 2;
  filter: grayscale(100%) blur(4px);
}

.overlay.apply-filter {
  background: linear-gradient(140deg, #03a9f400, #006ea059 33.61%, black);
}

.overlay.mesh {
  transition: 400ms all ease-in-out;
  animation: blinker 5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0.4;
  }
}

.analizing-face .camera-bottom {
  background: #75DED5;
}

.progress-bar-wrapper {
  background: rgba(0,0,0,0.4);
  height: 6px;
  margin-bottom: -6px;
  z-index: 2;
  position: relative;
  visibility: hidden;
}

.progress-bar {
  width: 0%;
  height: 100%;
  background: white;
}

.progress-bar-wrapper.active {
  visibility: visible;
}

.progress-bar-wrapper.active .progress-bar {
  width: 100%;
  transition: width 5s;
}

.topbar {
  background: rgba(0,0,0,0.2);
  height: 54px;
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  position: relative;
  flex: none;
  transition: 200ms background ease-out;
}

.topbar h1 {
  font-size: 15px;
  font-weight: 600;
  margin: 0px auto;
}

.topbar .float-button-left,
.topbar .float-button-right {
  top: auto;
}

.topbar .button-topbar {
  height: 54px;
  padding: 0 4rem;
}

.topbar .float-button-left {
  left: 0px;
}

.topbar .float-button-right {
  right: 0px;
}

.have-fixed-topbar {
  padding-top: 54px;
}

.profile-picture {
  height: 130px;
  width: 130px;
  border-radius: 65px;
  overflow: hidden;
  background: #000000;
  padding: 0;
}

.profile-picture img {
  max-width: 100%;
  max-height: 100%;
}

.profile-picture-block {
  background: rgba(0, 0, 0, 0.4);
  text-align: center;
  padding: 6rem 0;
}

.profile-picture-block .profile-picture {
  margin: auto;
}

.profile-picture-block .button-secondary {
  margin-top: 3rem;
}

.hide {
  display: none !important;
}

.no-opacity {
  opacity: 0;
  width: 0px !important;
  height: 0px !important;
  pointer-events: none;
}

.rai-activity-indicator {
  margin: auto;
}

.loader.screen {
  flex-direction: column;
  z-index: 99999;
}

.splash.screen {
  flex-direction: row;
  z-index: 99999;
  text-align: center;
  align-content: center;
  align-items: center;
  background: #ffffff;
  background: #1C1D2E;
  top: 0px;
  transition: all 400ms ease-in-out;

  .logo-big {
    max-width: 100px;
  }
}

.splash.screen h2 {
  font-size: 13px;
  line-height: 23px;
  text-align: center;
  margin-top: 5rem;
  letter-spacing: 1.5px;
}

.splash.screen .splash-center-wrapper {
  width: 100%;
}

.splash.screen.out {
  top: -50px;
  opacity: 0;
}

.splash.screen.hide {
  display: none;
}

.splash.screen .logo-big {
  margin: auto;
}

.screen-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  transition: width 350ms ease-in-out;

  /* left: calc(50% - 250px); */
}

.audio-wrapper {
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  flex: 1;
  display: flex;
  align-content: center;
  align-items: center;
  z-index: 2;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
}

.audio-wrapper audio {
  width: 40px;
  margin: auto;
}

.press-play-wrapper {
  margin: auto;
}

.press-play-wrapper h1 {
  margin-bottom: 6rem;
}

.blur-cover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.blur-cover.blur {
  filter: blur(5px);
}

p .red {
  background: #EE3636;
  width: auto;
  display: inline-block;
  padding: 2px 6px;
  margin-top: 4rem;
}

.meter {
  height: 18px;
  max-width: 258px;
  position: relative;
  margin: auto;
  background: #00000075;
  border-radius: 25px;
  padding: 5px;
  margin-bottom: 7rem;
}

.meter > span {
  display: block;
  height: 100%;
  -webkit-border-top-right-radius: 8px;
  -webkit-border-bottom-right-radius: 8px;
  -moz-border-radius-topright: 8px;
  -moz-border-radius-bottomright: 8px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  -webkit-border-top-left-radius: 20px;
  -webkit-border-bottom-left-radius: 20px;
  -moz-border-radius-topleft: 20px;
  -moz-border-radius-bottomleft: 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: #75DED5;
  box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3), inset 0 -2px 6px rgba(0, 0, 0, 0.4);
  position: relative;
  overflow: hidden;
  width: 10%;
  transition: all 0.2s;
  background-image: -webkit-linear-gradient(45deg, transparent, transparent 33%, rgba(0, 0, 0, 0.1) 34%, rgba(0, 0, 0, 0.1) 66%, transparent 66%), -webkit-linear-gradient(top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), -webkit-linear-gradient(left, #2cd3c6, #2cd3c6);
  background-size: 30px 18px, 100% 100%, 100% 100%;
  -webkit-animation: move 1.3s linear infinite;
  -moz-animation: move 1.3s linear infinite;
  animation: move 1.3s linear infinite;
}

.meter p {
  font-weight: 600;
  position: absolute;
  top: 50%;
  z-index: 9;
  left: 50%;
  transform: translate(-50%, -50%);
}

@-webkit-keyframes move {
  0% {
     background-position: 0 0;
  }
  100% {
     background-position: 30px 0px;
  }
}

@-moz-keyframes move {
  0% {
     background-position: 0 0;
  }
  100% {
     background-position: 30px 0px;
  }
}

@keyframes move {
  0% {
     background-position: 0 0;
  }
  100% {
     background-position: 30px 0px;
  }
}

.input-password-wrapper {
  position: relative;
}

.btn-show-password {
  position: absolute;
  right: 4rem;
  top: 13px;
  width: 20px;
  height: 20px;
  z-index: 2;
  background: url('/assets/icon-eye.png') center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.input-password-wrapper input[type=password] {
  font-size: 20px;
}

.icon-camera-big {
  height: 120px;
}

.lang-select {
  background: #ffffff;
  height: 46px;
  border-radius: 23px;
  width: 100%;
  padding: 0 2rem;
  margin: 0;
  margin-bottom: 3rem;
}

.screen-inner-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  box-sizing: border-box;
}

.language-selector {
   position: absolute;
   right: 3rem;
   top: 3rem;
   font-size: 16px;
   font-weight: 600;
   z-index: 2;
}

.language-selector span {
  opacity: 0.6;
}

.language-selector span.active {
  opacity: 1;
}

.language-selector .btn:hover {
  cursor: pointer;
}

.language-selector .divider {
  margin: 0 1rem;
}

/* Layout */

.scroll-content {
  overflow: scroll;
  overflow-x: hidden;
  padding: 6rem 4rem;
}

.flexible-center {
  display: flex;
  align-items: center;
  flex: 1;
}

.flexible-center>div {
  margin: auto;
}

.flexible-top {
  flex: 1;
  /* margin-top: 6rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-direction: row;
  min-height: 1px;
}

.fixed-bottom {
  min-height: 1px;
}

.float-button-left {
  position: absolute;
  left: 4rem;
  top: 5rem;
  z-index: 99;
}

.float-button-right {
  position: absolute;
  right: 4rem;
  top: 5rem;
}

.layout-overlay {
  position: absolute;
  z-index: 2;
  /*
  width: 100%;
  height: 100%;
  */
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
}

.layout-overlay.with-padding {
  padding: 0 24px;
  box-sizing: border-box;
}

/* Welcome screen */

.welcome.screen p {
  /* max-width: 280px; */
}

/*
.welcome.screen .fixed-bottom {
  width: 100%;
}
*/

/* Login screen */

.login.screen p {
  max-width: 280px;
}

/* LoginEmail screen */

.login-email.screen .button-primary {
  margin-top: 3rem;
}

.login-email.screen p {
  max-width: 280px;
}

.login-email.screen .fixed-bottom {
  margin-bottom: 9rem;
}

/* RememberCode screen */

.remember-code.screen .button-primary {
  margin-top: 3rem;
}

/* SignUp screen */

.signup.screen h1 {
  max-width: 254px;
  margin-bottom: 4rem;
}

.signup.screen .policy-block  {
  max-width: 260px;
  margin: auto;
  margin-bottom: 6rem;
  margin-top: 4rem;
}

.signup.screen .policy-block p {
  margin-bottom: 0rem;
  font-weight: 500;

  a, a:link, a:active, a:visited, a:hover {
    text-decoration: none;
    font-weight: 700;
    color: inherit;
  }
}

.signup.screen .button-secondary-no-bg {
  margin-top: 2rem;
}

.signup.screen button.inline {
  font-size: 12px;
  font-weight: 700;
  display: inline-block;
}

/* SignUpConfirmation screen */

.signup-confirmation.screen p {
  max-width: 280px;
}

.signup-confirmation.screen .button-secondary-no-bg {
  margin-top: 2rem;
}

.signup-confirmation.screen .fixed-bottom {
  margin-bottom: 9rem;
}

.signup-confirmation.screen .button-secondary-no-bg {
  /* max-width: 230px; */
  margin-left: auto;
  margin-right: auto;
}

/* RequestAccessCamera screen */

.request-access-camera.screen .flexible-top {
  margin-top: 6rem;
}

.request-access-camera.screen h1 {
  
}

.request-access-camera.screen p {

}

/* IOSDisclaimer screen */

.ios-disclaimer.screen .flexible-top {
  margin-top: 0;
}

/* CameraInaccessible screen */

.incompatible-browser.screen .flexible-top {
  margin-top: 0;
}

/* Thanks screen */

.thanks.screen .flexible-top {
  margin-top: 6rem;
}

/* CameraCalibration screen */

.camera-calibration.screen {
  padding: 0;
}

.camera-calibration.screen .flexible-top {
  margin-top: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.camera-calibration.screen .button-primary {
  max-width: 300px;
  margin: auto;
}

/*
.camera-calibration.screen .fixed-bottom,
.camera-calibration.screen p {
  margin-bottom: 0;
}
*/

.camera-calibration.screen .with-padding p {
  /* max-width: 238px; */
  /* margin-bottom: 7rem; */
}

.camera-calibration.screen .with-padding p span {
  display: inline-block;
}

.camera-calibration.screen .with-padding .fixed-bottom {
  margin-bottom: 12rem;

  p {
    color: #ffffff;
  }
}

.camera-calibration .fixed-bottom {
  width: 100%;
}

/* Experience screen */

.experience.screen {
  background: #121212;
  padding: 0px;
}

.experience .flexible-top {
  padding-bottom: 4rem;
  padding-top: 4rem;
  height: 0;
}

.experience .fixed-bottom {
  padding-bottom: 2rem;
}

.experience .fixed-bottom i {
  font-size: 24px;
}

.experience .button-primary {
  width: calc(100% - 48px);
  margin: 0 auto 4rem;
}

#playerVideo,
#playerVideoB,
#playerImage {
  display: flex;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  pointer-events: none;
}

.experience.film #playerVideo {
  width: 100%;
  margin: auto;
}

.experience.film #playerVideo.portrait {
  width: auto;
  height: 100%;
}

/*
#playerVideo,
#playerImage {
  width: 100%;
  margin: auto;
}
*/
/*
#playerVideo.portrait,
#playerImage.portrait {
  width: auto;
  height: 100%;
}
*/
/*
.experience .flexible-top {
  align-items: flex-end;
  padding-bottom: 4rem;
}
*/

/* ExperienceMoments screen */

.experience-moments.screen {
  padding: 0px;
  /* background: #121212; */
}

.experience-moments.screen .fixed-bottom {
  flex: 1;
  margin-bottom: 0;
  align-items: center;
  display: flex;
}

.experience-moments.screen .flexible-top {
  flex: none;
  margin-top: 3rem;
  z-index: 1;
}

.experience-moments.screen .float-button-left,
.experience-moments.screen .float-button-right {
  z-index: 2;
}

.experience-moments.screen .cards {
  padding-top: 8rem;
}

.experience-moments.screen h1 {
  margin-bottom: 2rem;
}

.experience-moments.screen .flexible-center h1 {
  max-width: 300px;
}

.experience-moments.screen .fixed-bottom .loader {
  margin: auto;
}

.screen-wrapper .screen.module-qa {
  overflow: auto;

  .flexible-top {
    align-items: initial;
    margin-bottom: 0rem;
  }

  .experience-progress {
    margin-bottom: 4rem;
  }
}

.qa-question-wrapper {
  width: 100%;
  padding-bottom: 6rem;

  h1 {
    margin-top: 10rem;
    margin-bottom: 8rem;
    font-size: 16px;
  }

  .answers {
    color: #ffffff;

    .answer {
      border: 1px #ffffff30 solid;
      /* background: #ffffff; */
      border-radius: 6px;
      padding: 3rem 2rem 3rem 4rem;
      margin-bottom: 3rem;
      text-align: left;
      white-space: pre-line;
      display: flex;

      .answer-text {
        flex: 1;
      }

      .answer-icon {
        align-items: center;
        display: flex;

        i {
          color: #ffffff35;
        }
      }
    }

    .answer:hover {
      cursor: pointer;
      border: 1px #ffffff90 solid;

      .answer-icon i {
        color: #ffffff90;
      }
    }
  }
}

/* Home screen */

.home.screen .fixed-bottom p {
  /* max-width: 233px; */
}

.home.screen .flexible-top {
  margin-top: 20rem;
}

.home.screen .float-button-right button {
  margin-left: 4rem;
}

.home.screen .float-top {
  margin: 0;
}

/* Options screen */

.options.screen {
  background: #121212 url('/assets/background-color.png') center center;
  background-size: cover;
  padding-left: 0;
  padding-right: 0;
}

.options.screen .content {
  background: rgba(0,0,0,0.2);
  padding-bottom: 8rem;
  text-align: center;
}

.options.screen .input.inline:last-child input {
  border: 0;
}

.options.screen .input.inline:last-child {
  border-bottom: 1px #464546 solid;
}

.options.screen .button-secondary {
  margin-top: 4rem;
}

.options.screen .button-secondary.red {
  color: #EE3636;
}

/* Policy and conditions screen */

.policy-and-conditions.screen {
  padding: 0;
}

.scroll-content p {
  margin-top: 6rem;
}

.redbull .screen.splash h2 {
  display: none;
}

/* Modules Recognition */

.screen.experience .flexible-top {
  box-sizing: border-box;
  max-height: 100%;
}

/*
.screen.experience .camera-wrapper,
.screen.experience .camera-crosshair  {
  opacity: 1;
  transition: opacity 0.1s;
  transition-delay: 5s;
}

.screen.experience.analizing-face .camera-wrapper,
.screen.experience.analizing-face .camera-crosshair {
  opacity: 0;
  transition: opacity 0.1s;
  transition-delay: 2s;
}
*/

@keyframes breath {
  0%  {
    opacity: 1;
  }
  30% {
    opacity: 0.6;
  }
  60% {
    opacity: 1;
  }
}

.experience {
  .analyzing-msg {
    position: absolute;
    top: 1rem;
    right: 1.2rem;
    width: auto;
    display: flex;
    display: none;
    align-items: center;
    color: #ff0000;
    font-size: 12px;
    font-weight: 600;
    animation: breath;
    animation-duration: 2s;
    animation-iteration-count: infinite;

    .dot {
      height: 12px;
      width: 12px;
      background: #ff0000;
      border-radius: 50%;
      margin-right: 1.5rem;
      margin-top: -2px;
    }
  }

  .video-preview {
    transform: rotateY(180deg);
    height: 100%;
    width: auto;
    filter: grayscale(100%);
  }

  .camera-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    /*
    top: 2rem;
    right: 1.2rem;
    */
    transition: all 0.3s;
    /* transition-delay: 3s; */

    width: auto;
    height: 150px;
    overflow: visible;

    margin-top: 0;
  }

  .camera-crosshair {
    z-index: 9;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 76px;
    height: 76px;
    opacity: 1;
    transition: opacity 0.3s;
    transition-delay: 3s;

    .TL {
      height: 3px;
      width: 12px;
    }

    .TR {
      height: 3px;
      width: 12px;
    }

    .RT {
      height: 9px;
      width: 3px;
      top: 3px;
    }

    .RB {
      height: 9px;
      width: 3px;
      bottom: 3px;
    }

    .BR {
      height: 3px;
      width: 12px;
    }

    .BL {
      height: 3px;
      width: 12px;
    }

    .LB {
      height: 9px;
      width: 3px;
      bottom: 3px;
    }

    .LT {
      height: 9px;
      width: 3px;
      top: 3px;
    }
  }

  .border-left {
    width: 2px;
    background: #2d3235;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
  }

  .border-right {
    width: 2px;
    background: #2d3235;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .border-top {
    height: 3px;
    background: #2d3235;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  .search-face-msg {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    font-size: 12px;
    font-weight: 600;
    transition: opacity 0.3s;
    transition-delay: 3s;
    padding: 1rem 1rem 1.5rem;
    background: #2d3235;
    border-radius: 0 0 6px 6px;
    color: #a3acaf;

    div {
      line-height: initial;
    }
  }
}

.experience.analizing-face {
  .camera-wrapper {
    transform: translateY(-8rem);
    opacity: 0;
    transition-delay: 0s;
  }
}

.camera-calibration {
  .layout-overlay {
    z-index: 4;
  }
}

.incompatible-device {
  display: flex;
  height: 100%;
  background: $secondary-color;
  width: 100%;

  .incompatible-devide-wrapper {
    width: 100%;
    max-width: 300px;
    text-align: center;
    margin: auto;

    img {
      width: 80px;
      margin-bottom: 8rem;
      margin-top: 12rem;
    }

    h2 {
      color: #EDEDF1;
      margin: 0;
      margin-bottom: 4rem;
      font-size: 16px;
      font-weight: 500;
    }

    p {
      color: #7f7d86;
      margin: 0 0 6rem;
      font-size: 15px;
    }
  }
}

.screen-wrapper .screen.loading-resources {
  .rai-activity-indicator {
    margin-bottom: 4rem;
    margin-top: 4rem;
  }

  p.small {
    font-size: 12px;
    font-weight: 600;
  }

  .fixed-bottom p {
    max-width: none;
  }
}

.process-warning {
  margin-top: 8rem;

  small {
    display: inline-block;
    margin-top: 2rem;
  }
}

.loading-resources-box {
  position: relative;
  width: 260px;
  margin: auto;

  .loading-resources-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    p {
      margin-bottom: 0rem;
      min-height: 25px;
    }

    p.animated {
      font-size: 30px;
      color: #278DF4;
      letter-spacing: 2px;
      text-align: left;
      margin: auto;
      width: 23px;
    }
  }
}

.screen.instructions {
  /* background: #1C1D2E; */
  padding: 0rem;

  .screen-inner-wrapper {
    overflow: auto;
    padding-top: 10rem;

    .flexible-top {
      flex: none;
      display: inherit;
      margin-top: 0rem;
      margin-bottom: 6rem;

      .logo-big {
        max-width: 160px;
      }
    }

    .instructions {
      max-width: 650px;
      margin: auto;

      h1 {
        /* color: #00CDEE; */
      }

      ul {
        margin: auto;
        list-style: decimal;
        text-align: left;

        li {
          margin-bottom: 3rem;
          padding-left: 2rem;
          font-size: 17px;
        }
      }

      b {
        /* color: #ffffff; */
        font-weight: 600;
      }
    }

    .policy-block {
      max-width: 290px;

      p {
        margin-left: 4rem;
      }
    }

    .button-primary {
      /* background: #00CDEE; */
    }

    .fixed-bottom {
      min-height: inherit;
      margin-bottom: 12rem;
    }
  }
}

.logo-byalyze {
  position: relative;
  z-index: 9;

  p {
    margin: 0;
    background: #ffffff;
    padding: 1rem 2rem;
    border-radius: 6px 6px 0 0;
    border: 1px #D4D6D7 solid;
    position: absolute;
    right: 0;
    top: 4rem;
    transform-origin: top right;
    transform: rotate(-90deg) translateY(-100%);

    a {
      font-weight: 800;
      font-size: 12px;
      text-decoration: none;
      color: #1b1e2e;
      color: #15121A;
    }
  }
}

.create-your-own {
  margin-top: 8rem;

  h2 {
    font-size: 24px;
    line-height: normal;
    font-weight: 600;
    margin-bottom: 4rem;
  }
}


.show-player-a {
  #playerVideoB {
    // opacity: 0;
    display: none;
  }
}

.show-player-b {
  #playerVideo {
    // opacity: 0;
    display: none;
  }
}


.experience.screen {
  .float-player-bar {
    height: 50px;
    display: flex;
    align-items: center;
    transition: visibility 0.3s linear, opacity 0.3s linear;

    .progress-bar {
      background: #c5c5c596;
      height: 5px;
      margin-right: 2rem;
      flex: 1 1;
      margin-left: 4rem;
      margin-top: 2px;

      .progress {
        background: #ffffff;
        height: 100%;
        width: 20%;
      }
    }

    .progress-time {
      color: #fff;
      font-weight: 500;
      font-size: 14px;
      text-align: left;
      margin-right: 4rem;
    }
  }

  i.icon.icon-sound-1 {
    font-size: 25rem;
    opacity: .3;
    margin: 0 auto;
  }
}

.experience.screen.film {
  .flexible-top {
    padding: 0;
  }

  .pause-message {
    background: #00000080;
    color: #fff;
    /* border: 1px #fff solid; */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 2rem 3rem;
    white-space: nowrap;

    .button-primary {
      width: auto;
      padding: 0 3rem;
      margin-top: 4rem;
      margin-bottom: 1rem;
      height: 42px;
      font-size: 12px;
    }
  }

  .float-player-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: black;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
    padding: 0 2rem;

    .button-play {
      display: flex;
      color: white;
      text-align: left;
      align-items: center;

      &:hover {
        cursor: pointer;
      }

      i {
        color: $white-color;
      }

      p {
        color: white;
        margin: 0;
        margin-left: 1rem;
        font-size: 12px;
        font-weight: 600;
      }
    }
  }
}

.rc-dialog-root {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  text-align: left;

  .recharged-credit-input {
    margin-top: 6rem;
  }

  .rc-dialog.loading .main-button {
    span {
      visibility: hidden;
    }
  }

  .rc-dialog-mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #6b8286;
    opacity: 0.8;
    z-index: 9;
  }

  .rc-dialog-mask-hidden {
    display: none;
  }

  .rc-dialog-content {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    background: $white-color;
    border: 0px $gray-color-2 solid;
    height: auto;
    transform: translate(-50%, -50%);
    border-radius: 2px;
    padding: 3rem 3rem;
    box-sizing: border-box;
    min-width: 500px;

    button {
      height: 42px;
      font-size: 13px;
    }
  }

  .rc-dialog.small-modal {
    .rc-dialog-content {
      max-width: 500px;
    }
  }

  .rc-dialog.dialogInfo {
    .rc-dialog-close {
      right: -5rem;
      top: -7rem;
      @media only screen and (max-width: 1024px) {
        right: 1rem;
        top: 0;
        i {
          color: $gray-color-2;
        }
      }
    }
    .rc-dialog-content {
      padding-bottom: 0;
      min-width: 600px;
      @media only screen and (max-width: 600px) {
        min-width: auto;
      }
    }
    .rc-dialog-body .modal-body {
      background: $white-color;
      border-top: 1px solid $gray-color-1;
      @media only screen and (max-width: 600px) {
        padding: 3rem;
      }
      @media only screen and (max-height: 800px) {
        max-height: 70vh;
        overflow-y: scroll;
      }
    }
  }

  .rc-dialog.dialogInfo.dialogInfoLarge {
    .rc-dialog-content {
      @media only screen and (min-width: 1024px) {
        min-width: 990px;
      }
    }
  }

  .rc-dialog-close {
    display: none;
    background: transparent;
    border: 0;
    position: absolute;
    right: -8rem;
    top: -7rem;

    i {
      color: $white-color;
      font-size: 16px;
    }
  }

  .rc-dialog-close:hover {
    cursor: pointer;
  }

  .rc-dialog-close:focus {
    outline: 0;
    border: 0;
  }

  .rc-dialog-title {
    color: $secondary-color;
    font-size: 24px;
    font-weight: 300;
    margin-bottom: 4rem;
  }

  .rc-dialog-body {
    color: $secondary-color;

    .modal-subtitle {
      margin-top: -3rem;
      margin-bottom: 2rem;
      font-size: 13px;
      font-weight: 400;
    }

    .modal-body {
      background: $gray-color-1;
      margin-left: -3rem;
      margin-right: -3rem;
      padding: 2rem 3rem;

      p {
        /* margin: 0; */
        font-size: 15px;
        margin-bottom: 0;
      }
    }

    a {
      color: $main-color;
      text-decoration: none;
    }

    button.full-width {
      margin: 0;
    }

    .policy-block {
      margin-bottom: 2rem;

      p {
        margin: 0;
      }

      a {
        color: $secondary-color;
        font-weight: 500;
      }
    }

    h2 {
      font-weight: 500;
      font-size: 16px;
      margin-top: 5rem;
    }

    .large {
      font-weight: 500;
      font-size: 16px;
    }
  }

  ul {
    margin: 0;
    padding-left: 3rem;

    li {
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .dialog-buttons {
    text-align: right;
    padding-bottom: 3rem;
    margin-top: 4rem;
    /*
    margin-right: -2rem;
    margin-left: -2rem;
    margin-bottom: -2rem;
    */

    button {
      display: inline-block;
      padding: 0 3rem;
      width: auto;
      // margin-left: 4rem;
      @media only screen and (max-width: 1024px) {
        line-height: inherit;
        padding: 0 3rem;
      }
    }

    .inline.button-red {
      color: $warning-color;
    }
  }
}

.user-information {
  // line-height: initial;
  // color: white;
  // z-index: 10;
  // font-size: 17px;
  // font-weight: 500;
  // /* width: 800px; */
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  // opacity: 0.2;

  line-height: initial;
  color: white;
  z-index: 10;
  font-size: 12px;
  font-weight: 500;
  position: absolute;
  top: 3rem;
  left: 3rem;
  opacity: 1;
  text-align: left;
  transition: visibility 0.3s linear, opacity 0.3s linear;
}

.user-information.hidden,
.float-player-bar.hidden {
  visibility: hidden;
  opacity: 0;
}

.button-fullscreen {
  color: #fff;
  text-align: right;
  margin-right: 3rem;

  &:hover {
    cursor: pointer;
  }

  i {
    color: #fff;
    font-size: 16px !important;
  }

  &.film {
    margin-left: 2rem;
    margin-right: 0;
  }

  &.qa {
    position: fixed;
    bottom: 2rem;
    right: 0;
  }
}