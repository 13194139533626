.none-enter {
  z-index: 1;
}
.none-exit {
  z-index: 0;
  display: none;
}
.none-enter-done {
  z-index: 1;
}

.slide-in-enter {
  z-index: 1;
  transform: translateX(100%);
}
.slide-in-enter-active {
  z-index: 1;
  transform: translateX(0%);
}
.slide-in-enter-done {
  z-index: 1;
}
.slide-in-exit {
  z-index: -1;
  transform: translateX(0%);
}
.slide-in-exit-active {
  z-index: -1;
  transform: translateX(-40%);
}


.slide-out-enter {
  z-index: -1;
  transform: translateX(-40%);
}
.slide-out-enter-active {
  z-index: -1;
  transform: translateX(0%);
}
.slide-out-enter-done {
  z-index: 1;
}
.slide-out-exit {
  z-index: 1;
  transform: translateX(0%);
}
.slide-out-exit-active {
  z-index: 1;
  transform: translateX(100%);
}

.no-transition-exit-active {
  z-index: -1;
}

.slide-out-exit-active,
.slide-out-enter-active,
.slide-in-exit-active,
.slide-in-enter-active {
  -webkit-transition: all 350ms ease-in-out;
     -moz-transition: all 350ms ease-in-out;
       -o-transition: all 350ms ease-in-out;
          transition: all 350ms ease-in-out;
}