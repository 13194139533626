.skip-modules-container {
  position: relative;
  z-index: 9;

  .skip-modules {
    position: absolute;
    top: 4rem;
    left: 0;
    color: #1C1D2E;
    text-align: left;
    display: flex;

    .expand-button {
      display: flex;
      align-items: center;
      background: #fff;
      padding: 1rem;
      border: 1px #D4D6D7 solid;
      border-left: none;
      z-index: 1;
      transition: border-radius 0.3s ease-in-out;

      i {
        transform: rotate(-180deg);
        transition: transform 0.3s linear;
      }

      &:hover {
        background: #EDEEEE;
        cursor: pointer;

        i {
          color: #00C7E7;
        }
      }

      &.closed {
        border-radius: 0 6px 6px 0;

        i {
          transform: rotate(0deg);
          transition: transform 0.3s linear;
        }
      }
    }

    .module-control {
      background: #fff;
      border-radius: 0 6px 6px 0;
      border: 1px #D4D6D7 solid;
      border-left: none;
      padding: 0;
      transform: translateX(0);
      transition: all 0.3s ease-in-out;

      &.hidden {
        visibility: hidden;
        transform: translateX(-100%);
      }

      .module-control-form {
        display: flex;
        align-items: flex-end;
        padding: 3rem 4rem;

        .input {
          margin: 0;

          label {
            margin-bottom: 1rem;
            font-size: 12px;
          }
        }

        .input-wrapper.select i {
          display: none;
        }
      }
    }

    .arrow-buttons {
      display: flex;
      justify-content: space-between;

      .arrow-button {
        display: flex;
        flex-direction: column;
        flex: 1 1;
        text-align: center;

        &.disabled {
          opacity: .6;
        }

        span {
          font-size: 12px;
        }

        button {
          background-color: #D4D6D7;
          color: #1C1D2E;
          padding: 1rem 2rem;

          &:hover:not([disabled]) {
            cursor: pointer;
            background: #EDEEEE;

            i {
              color: #00C7E7;
            }
          }

          &[disabled]:hover {
            cursor: default;
          }
        }

        &:last-of-type {
          padding-left: 1px;

          button {
            border-radius: 0 0 6px 0;
          }
        }
      }
    }
  }
}