.notification {
  font-size: 14px;
  background: #EE3636;
  color: #fff;
  font-weight: 700;
  position: absolute;
  top: -64px;
  height: 64px;
  left: 12px;
  right: 12px;
  padding: 2rem 4rem;
  text-align: center;
  box-sizing: border-box;
  z-index: 99;
  transition: all 300ms ease-in-out;
  display: flex;
  text-align: center;
  align-items: center;
  border-radius: 6px;
}

.notification.visible {
  top: 12px;
}

.notification p {
  max-width: none !important;
  margin: 0px auto;
}