
.camera-crosshair {
    width: 270px;
    height: 270px;
    margin: auto;
    position: relative;
    top: -5%;
  }

.camera-crosshair div {
    background: #ffffff50;
    position: absolute;
}

.analizing-face .camera-crosshair div {
    background: #ffffff;
  }

.TL {
    height: 8px;
    width: 30px;
    top: 0px;
    left: 0px;
}

.TR {
    height: 8px;
    width: 30px;
    top: 0px;
    right: 0px;
}

.RT {
    height: 20px;
    width: 8px;
    top: 8px;
    right: 0px;
}

.RB {
    height: 20px;
    width: 8px;
    bottom: 8px;
    right: 0px;
}

.BR {
    height: 8px;
    width: 30px;
    bottom: 0px;
    right: 0px;
}

.BL {
    height: 8px;
    width: 30px;
    bottom: 0px;
    left: 0px;
}

.LB {
    height: 20px;
    width: 8px;
    bottom: 8px;
    left: 0px;
}

.LT {
    height: 20px;
    width: 8px;
    top: 8px;
    left: 0px;
}
