.experience-progress {
    display: inline-flex;
}

.experience-progress .step {
    background:rgba(255, 255, 255, 0.40);
    height: 2rem;
    width: 2rem;
    border-radius: 1rem;
    margin: 0 1rem;
    overflow: hidden;
    transition: width 300ms;
}

.experience-progress .step.active {
    background: #ffffff;
    width: 6rem;
}

.experience-progress .step.finished {
    background: #ffffff;
}
