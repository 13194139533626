@media only screen and (min-width: 1200px) {
  body {
    font-size: 17px;
    line-height: 25px;
  }

  h1 {
    font-size: 21px;
    line-height: 28px;
  }

  .button-primary {
    height: 52px;
    border-radius: 26px;
    font-size: 15px;
  }

  .policy-block p {
    font-size: 11px;
  }

  .signup.screen button.inline {
    font-size: 11px;
  }

  .signup.screen .policy-block {
    margin-top: 3rem;
  }
}

@media only screen and (min-width: 1000px) {
  .screen {
    overflow: hidden;
  }

  .screen-wrapper {
    /* width: 500px; */
    background: #121212;
  }

  .flexible-top {
    margin-bottom: 10rem;
  }

  .float-top {
    margin-bottom: 10rem;
  }

  /*
  .desktop-cover {
    display: flex;
    background: #121212 url('/assets/bg-woman.jpg') center center;
    background-size: cover;
  }

  .desktop-cover.alternative-bg {
    background: #121212 url('/assets/bg-man.jpg') center center;
    background-size: cover;
  }
  */

  .signup.screen button.inline {
    font-size: 10px;
  }

  .screen-inner-wrapper {
    height: auto;
  }

  .flexible-top {
    margin-bottom: 10rem;
  }

  .float-top {
    margin-bottom: 10rem;
  }
/*
  #playerVideo,
  #playerImage {
    width: auto;
    height: 100%;
  }

  #playerVideo,
  #playerImage {
    width: auto;
    height: 99%;
  }

  #playerVideo.portrait,
  #playerImage.portrait {
    width: 100%;
    margin: auto;
  }
  */
}

@media only screen and (min-width: 700px) and (max-width: 999px) {
  body {
    font-size: 20px;
    line-height: 28px;
  }

  h1 {
    font-size: 22px;
    margin-bottom: 5rem;
  }

  .button-primary {
    height: 56px;
    border-radius: 28px;
    font-size: 16px;
  }

  .policy-block p,
  .signup.screen button.inline {
    font-size: 13px;
  }

  .input input {
    height: 56px;
    border-radius: 28px;
    font-size: 16px;
  }

  .input:before {
    top: 19px;
  }

  body .fixed-bottom,
  body .flexible-top,
  body .swiper-slide div {
    width: 400px;
  }

  .btn-show-password {
    top: 16px;
    width: 26px;
    height: 26px;
  }

  .camera-bottom p {
    font-size: 22px;
    line-height: 32px;
  }

  .logo-small {
    max-width: 140px;
  }

  .logo-big {
    max-width: 200px;
  }

  body .thanks.screen .logo-small,
  body .request-access-camera.screen .logo-small {
    margin-bottom: 14rem;
  }

  .signup.screen .policy-block {
    max-width: 330px;
  }

  .camera-calibration.screen .logo-small {
    margin-top: 4rem;
  }

  body .camera-calibration.screen .fixed-bottom {
    width: 100%;
  }

  .camera-bottom p {
    max-width: 315px;
  }

  .swiper-slide {
    padding-bottom: 16rem;
  }

  .swiper-container-horizontal>.swiper-pagination-bullets {
    bottom: 36px;
  }
}

@media only screen and (min-width: 700px) {
  .screen:not(.welcome) .screen-inner-wrapper {
    height: auto;
  }

  .screen:not(.welcome):not(.camera-calibration) .flexible-top {
    /* margin-bottom: 12rem; */
  }

  .screen:not(.welcome) .float-top {
    margin-bottom: 10rem;
  }

  .thanks.screen .logo-small,
  .request-access-camera.screen .logo-small {
    margin-bottom: 10rem;
  }

  .thanks.screen .flexible-top,
  .home.screen .flexible-top {
    margin-top: 0rem;
  }

  .thanks.screen .fixed-bottom,
  .home.screen .fixed-bottom {
    margin-bottom: 0;
  }

  .fullwidth .desktop-cover {
    left: 100%;
  }

  .fullwidth .screen-wrapper {
    width: 100%;
  }

  .fullwidth .screen:not(.welcome) .flexible-top,
  .screen.experience .flexible-top {
    flex: 1;
    margin-bottom: 0rem;
  }

  .screen.iberia .logo-small {
    margin-bottom: 10rem;
  }
}

@media only screen and (min-width: 500px) {
  .fixed-bottom,
  .flexible-top,
  .swiper-slide div {
    width: 360px;
    margin-left: auto;
    margin-right: auto;
  }

  .fullwidth .fixed-bottom,
  .fullwidth .flexible-top {
    width: 100%;
  }

  .screen {
    padding-bottom: 3%;
    padding-top: 3%;
  }
}

@media only screen and (max-height: 667px) {

  /*
  .logo-big {
    max-width: 160px;
  }

  .flexible-top {
    margin-top: 6rem !important;
  }

  .fixed-bottom {
    margin-bottom: 6rem !important;
  }

  .signup.screen .fixed-bottom,
  .signup-confirmation.screen .fixed-bottom {
    margin-bottom: 4rem !important;
  }
  */
}


@media only screen and (max-width: 699px) {
  body .screen:not(.loading-resources) .screen-inner-wrapper .flexible-top,
  .screen.instructions .screen-inner-wrapper .flexible-top {
    flex: 1;
  }

  body .screen.loading-resources .screen-inner-wrapper .flexible-top {
    flex: inherit;
    margin-bottom: 12rem;
  }

  body .screen.loading-resources .screen-inner-wrapper {
    height: auto;
  }

  body .logo-byalyze {
    position: fixed;
    bottom: inherit;
    top: -1px;
    right: 4rem;
  }

  body .logo-byalyze p {
    border-radius: 0 0 6px 6px;
    transform: unset;
    transform-origin: unset;
    position: relative;
  }

  body .button-fullscreen {
    display: none;
  }
}