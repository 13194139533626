.button-primary {
    background: #000000;
    height: 46px;
    width: 100%;
    border: 0;
    border-radius: 23px;
    color: #ffffff;
    font-family : inherit;
    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
}

.button-secondary {
    background: #000000;
    height: 36px;
    width: auto;
    border: 0;
    border-radius: 18px;
    color: #ffffff;
    font-family: inherit;
    font-size: 13px;
    font-weight: 500;
    text-transform: none;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    padding: 0 2rem;
    margin: auto;
}

.button-secondary-no-bg {
    background: none;
    border: 0;
    outline: 0;
    margin: 0;
    padding: 0;
    color: #ffffff;
    font-family: inherit;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    position: relative;
    height: 46px;
    width: 100%;
    border-radius: 23px;
    overflow: hidden;
    align-items: unset;
    text-transform: none;
}

.button-primary:focus,
.button-secondary:focus,
.button-text:focus {
    outline: 0;
}

.button-primary:disabled {
    /*
    color: #545555;
    background: #161616;
    */
}

.button-icon {
    width: 66px;
}

.button-icon img {
    max-width: 26px;
}

.button-text {
    flex: 1;
}

button.inline {
    background: transparent;
    border: 0;
    outline: 0;
    padding: 2rem 1rem;
}

button.inline:hover {
    cursor: pointer;
}


/* Social buttons */

.button-facebook {
    background: #3C5A99;
    margin-bottom: 2rem;
}

.button-google {
    background: #DB4437;
}

/* Button animation */

.ripple {
    position: absolute;
    background: rgba(256,256,256,.25);
    border-radius: 100%;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    pointer-events: none;
}

.ripple.show {
    -webkit-animation: ripple 300ms ease-out;
    animation: ripple 300ms ease-out;
}

@-webkit-keyframes
ripple { to {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
    }
}
@keyframes
ripple { to {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
    }
}