@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700&display=swap);
.notification {
  font-size: 14px;
  background: #EE3636;
  color: #fff;
  font-weight: 700;
  position: absolute;
  top: -64px;
  height: 64px;
  left: 12px;
  right: 12px;
  padding: 2rem 4rem;
  text-align: center;
  box-sizing: border-box;
  z-index: 99;
  transition: all 300ms ease-in-out;
  display: flex;
  text-align: center;
  align-items: center;
  border-radius: 6px; }

.notification.visible {
  top: 12px; }

.notification p {
  max-width: none !important;
  margin: 0px auto; }

/* Fonts */
/* 400 = regular / 500 = medimum / 600 = semibold / 700 = bold */
@font-face {
  font-family: 'sounditi-client';
  src: url(/static/media/sounditi-client.52379f63.eot);
  src: url(/static/media/sounditi-client.52379f63.eot#iefix) format("embedded-opentype"), url(/static/media/sounditi-client.d847d2ba.woff) format("woff"), url(/static/media/sounditi-client.58e911d4.ttf) format("truetype"), url(/static/media/sounditi-client.b26e2b72.svg#sounditi-client) format("svg");
  font-weight: normal;
  font-style: normal; }

.icon {
  font-family: "sounditi-client";
  font-style: normal;
  font-weight: normal;
  speak: none;
  text-decoration: inherit;
  text-align: center;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #7f7d86;
  font-size: 16px; }

/* General */
html {
  font-size: 6px;
  background: #000000;
  overflow: hidden;
  position: fixed;
  height: 100%;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px; }

body {
  margin: 0;
  font-family: "Montserrat", "Helvetica Neue", sans-serif;
  color: #ffffff;
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  text-align: center;
  overflow: hidden;
  height: 100%; }

#root {
  height: 100%; }

h1 {
  font-size: 18px;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 3rem;
  margin-left: auto;
  margin-right: auto;
  line-height: 24px;
  margin-left: auto;
  margin-right: auto;
  white-space: pre-line; }

p {
  margin-top: 0px;
  margin-bottom: 6rem;
  margin-left: auto;
  margin-right: auto;
  white-space: pre-line; }

.version {
  position: fixed;
  bottom: 5px;
  left: 10px;
  font-size: 12px;
  opacity: 0; }

span.more-large {
  font-size: 16px; }

button {
  margin: 0px;
  padding: 0px;
  border: 0;
  position: relative;
  align-items: center;
  background: transparent;
  font-family: inherit;
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
  overflow: hidden; }

button:focus {
  outline: 0; }

button:hover {
  cursor: pointer; }

button div,
button img,
button p {
  pointer-events: none; }

.input.invalid .input-row .item {
  border-color: #EE3636; }

.input-row {
  display: flex;
  border: 0px #EDEEEE solid; }
  .input-row .input {
    margin: 0 1rem;
    width: 100%; }
  .input-row .item {
    text-align: center;
    display: flex;
    align-items: center;
    border: 1px #EDEEEE solid;
    margin-left: -1px;
    background-color: #ffffff; }
    .input-row .item p {
      margin: 0 auto;
      line-height: 46px;
      color: #1C1D2E !important;
      font-family: inherit;
      font-size: 13px;
      font-weight: 500; }
  .input-row .item:first-child {
    margin-left: 0;
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px; }
  .input-row .item:last-child {
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px; }
  .input-row .item.active {
    position: relative;
    z-index: 2;
    border-color: #00CDEE; }
  .input-row .item.active,
  .input-row .item:hover {
    cursor: pointer; }
    .input-row .item.active p,
    .input-row .item:hover p {
      color: #00CDEE; }

.input-row.select-item div {
  flex: 1 1; }

.float-top {
  margin-top: 10rem;
  z-index: 1; }

.float-top h1 {
  max-width: 250px;
  margin-top: 2rem; }

.center {
  margin: auto; }

.no-margin-bottom {
  margin-bottom: 2rem; }

.screen.swiper-container {
  padding: 0;
  align-items: center; }

.swiper-slide {
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  padding-bottom: 11rem;
  box-sizing: border-box;
  flex-flow: column-reverse; }

.swiper-slide div {
  display: flex;
  flex-direction: column;
  flex-flow: column-reverse; }

.swiper-pagination-bullet {
  background: #ffffff;
  transition: all 0.2s ease-in-out; }

.swiper-pagination-bullet-active {
  opacity: 1; }

.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 24px; }

.swiper-pagination-bullet-active {
  width: 16px;
  border-radius: 4px; }

.parallax-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center; }

.screen {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff;
  color: #1C1D2E;
  /* background: #121212 url('/assets/background.png') center center; */
  background-size: cover;
  /* padding: 0 24px; */
  display: flex;
  flex-direction: column;
  /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3); */
  z-index: 0;
  overflow: auto;
  transform: translateX(0%);
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 6rem; }
  .screen .fixed-bottom p {
    max-width: 415px;
    white-space: pre-line; }

.parallax-bg {
  display: none; }

.desktop-cover {
  position: absolute;
  width: calc(100% - 500px);
  top: 0;
  bottom: 0;
  right: 0;
  left: 500px;
  background: black;
  z-index: 2;
  display: block;
  /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3); */
  transition: width 350ms ease-in-out;
  align-content: center;
  align-items: center;
  overflow: hidden;
  /*
  width: calc(50% - 250px);
  left: calc(50% + 250px);
  */ }
  .desktop-cover .logo-byalyze {
    width: auto;
    position: absolute;
    bottom: 5rem;
    right: 5rem;
    left: auto;
    height: auto;
    top: auto;
    z-index: 9;
    background: black;
    padding: 1rem 2rem;
    transform: none;
    font-weight: 500; }
    .desktop-cover .logo-byalyze p {
      overflow: hidden;
      white-space: nowrap;
      margin: 0;
      font-size: 12px; }
      .desktop-cover .logo-byalyze p a {
        color: #ffffff;
        text-decoration: none; }
    .desktop-cover .logo-byalyze img {
      width: 100%; }

/*
.bg-parallax {
  background: #121212 url('/assets/bg-woman-parallax.jpg') center center;
  background-size: cover;
}

.bg-parallax-mesh {
  background: url('/assets/bg-woman-parallax-mesh.png') center center;
  background-size: cover;
}
*/
.desktop-cover div {
  width: 110%;
  height: 110%;
  position: absolute;
  top: -5%;
  left: -5%;
  transform: translate(-50%, -50%); }

.backgroud-filter {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.6;
  /*
  background: linear-gradient(-45deg, #EE7752, #E73C7E, #23A6D5, #23D5AB);
  */
  background-size: 400% 400%;
  -webkit-animation: Opacity 15s ease infinite;
  animation: Opacity 15s ease infinite; }

@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }

@keyframes Gradient {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }

.logo-text {
  font-size: 22px;
  font-weight: 600; }

.logo-big {
  max-width: 90px;
  margin: auto; }

.logo-small {
  max-width: 90px;
  margin: auto; }

.social-buttons-separator {
  margin-top: 3rem;
  margin-bottom: 3rem; }

.tag-black {
  background: #000000;
  color: #ffffff;
  font-weight: 600;
  padding: 2px 6px; }

input[type=number] {
  /* -webkit-text-security: disc; */ }

.message-black {
  font-weight: 600;
  color: white;
  background: #000000;
  padding: 1rem;
  margin-bottom: 0; }

.camera-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 3; }

.camera-fullscreen {
  /*
  position: absolute;
  box-sizing: content-box;
  width: auto;
  height: auto;
  left: 50%;
  top: 50%;
  -o-transform: translate(-50%, -50%) rotateY(180deg);
  -ms-transform: translate(-50%, -50%) rotateY(180deg);
  -moz-transform: translate(-50%, -50%) rotateY(180deg);
  -webkit-transform: translate(-50%, -50%) rotateY(180deg);
  transform: translate(-50%, -50%) rotateY(180deg);
  z-index: 0;
  */
  position: fixed;
  visibility: hidden; }

.camera-calibration .camera-fullscreen {
  position: absolute;
  box-sizing: content-box;
  width: auto;
  height: auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotateY(180deg);
  z-index: 0;
  visibility: visible; }

.experience .camera-fullscreen {
  /* filter: grayscale(100%) blur(5px); */ }

.camera-fullscreen.landscape {
  height: 100%; }

.camera-fullscreen.portrait {
  width: 100%; }

.black-and-white {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%); }

.camera-bottom {
  background: #000000;
  padding: 5rem 4rem; }

.camera-bottom p {
  max-width: 230px;
  margin: auto;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px; }

.analizing-face .camera-bottom p {
  max-width: 305px; }

.vertical-center {
  flex: 1 1;
  display: flex; }

.overlay {
  z-index: 99;
  width: 1280px;
  height: 720px;
  position: absolute;
  border: 0px red solid;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotateY(180deg); }

.overlay.picture {
  z-index: 2;
  -webkit-filter: grayscale(100%) blur(4px);
          filter: grayscale(100%) blur(4px); }

.overlay.apply-filter {
  background: linear-gradient(140deg, #03a9f400, #006ea059 33.61%, black); }

.overlay.mesh {
  transition: 400ms all ease-in-out;
  -webkit-animation: blinker 5s linear infinite;
          animation: blinker 5s linear infinite; }

@-webkit-keyframes blinker {
  50% {
    opacity: 0.4; } }

@keyframes blinker {
  50% {
    opacity: 0.4; } }

.analizing-face .camera-bottom {
  background: #75DED5; }

.progress-bar-wrapper {
  background: rgba(0, 0, 0, 0.4);
  height: 6px;
  margin-bottom: -6px;
  z-index: 2;
  position: relative;
  visibility: hidden; }

.progress-bar {
  width: 0%;
  height: 100%;
  background: white; }

.progress-bar-wrapper.active {
  visibility: visible; }

.progress-bar-wrapper.active .progress-bar {
  width: 100%;
  transition: width 5s; }

.topbar {
  background: rgba(0, 0, 0, 0.2);
  height: 54px;
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  position: relative;
  flex: none;
  transition: 200ms background ease-out; }

.topbar h1 {
  font-size: 15px;
  font-weight: 600;
  margin: 0px auto; }

.topbar .float-button-left,
.topbar .float-button-right {
  top: auto; }

.topbar .button-topbar {
  height: 54px;
  padding: 0 4rem; }

.topbar .float-button-left {
  left: 0px; }

.topbar .float-button-right {
  right: 0px; }

.have-fixed-topbar {
  padding-top: 54px; }

.profile-picture {
  height: 130px;
  width: 130px;
  border-radius: 65px;
  overflow: hidden;
  background: #000000;
  padding: 0; }

.profile-picture img {
  max-width: 100%;
  max-height: 100%; }

.profile-picture-block {
  background: rgba(0, 0, 0, 0.4);
  text-align: center;
  padding: 6rem 0; }

.profile-picture-block .profile-picture {
  margin: auto; }

.profile-picture-block .button-secondary {
  margin-top: 3rem; }

.hide {
  display: none !important; }

.no-opacity {
  opacity: 0;
  width: 0px !important;
  height: 0px !important;
  pointer-events: none; }

.rai-activity-indicator {
  margin: auto; }

.loader.screen {
  flex-direction: column;
  z-index: 99999; }

.splash.screen {
  flex-direction: row;
  z-index: 99999;
  text-align: center;
  align-content: center;
  align-items: center;
  background: #ffffff;
  background: #1C1D2E;
  top: 0px;
  transition: all 400ms ease-in-out; }
  .splash.screen .logo-big {
    max-width: 100px; }

.splash.screen h2 {
  font-size: 13px;
  line-height: 23px;
  text-align: center;
  margin-top: 5rem;
  letter-spacing: 1.5px; }

.splash.screen .splash-center-wrapper {
  width: 100%; }

.splash.screen.out {
  top: -50px;
  opacity: 0; }

.splash.screen.hide {
  display: none; }

.splash.screen .logo-big {
  margin: auto; }

.screen-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  transition: width 350ms ease-in-out;
  /* left: calc(50% - 250px); */ }

.audio-wrapper {
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  flex: 1 1;
  display: flex;
  align-content: center;
  align-items: center;
  z-index: 2;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0; }

.audio-wrapper audio {
  width: 40px;
  margin: auto; }

.press-play-wrapper {
  margin: auto; }

.press-play-wrapper h1 {
  margin-bottom: 6rem; }

.blur-cover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.blur-cover.blur {
  -webkit-filter: blur(5px);
          filter: blur(5px); }

p .red {
  background: #EE3636;
  width: auto;
  display: inline-block;
  padding: 2px 6px;
  margin-top: 4rem; }

.meter {
  height: 18px;
  max-width: 258px;
  position: relative;
  margin: auto;
  background: #00000075;
  border-radius: 25px;
  padding: 5px;
  margin-bottom: 7rem; }

.meter > span {
  display: block;
  height: 100%;
  -webkit-border-top-right-radius: 8px;
  -webkit-border-bottom-right-radius: 8px;
  -moz-border-radius-topright: 8px;
  -moz-border-radius-bottomright: 8px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  -webkit-border-top-left-radius: 20px;
  -webkit-border-bottom-left-radius: 20px;
  -moz-border-radius-topleft: 20px;
  -moz-border-radius-bottomleft: 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: #75DED5;
  box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3), inset 0 -2px 6px rgba(0, 0, 0, 0.4);
  position: relative;
  overflow: hidden;
  width: 10%;
  transition: all 0.2s;
  background-image: -webkit-linear-gradient(45deg, transparent, transparent 33%, rgba(0, 0, 0, 0.1) 34%, rgba(0, 0, 0, 0.1) 66%, transparent 66%), -webkit-linear-gradient(top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), -webkit-linear-gradient(left, #2cd3c6, #2cd3c6);
  background-size: 30px 18px, 100% 100%, 100% 100%;
  -webkit-animation: move 1.3s linear infinite;
  animation: move 1.3s linear infinite; }

.meter p {
  font-weight: 600;
  position: absolute;
  top: 50%;
  z-index: 9;
  left: 50%;
  transform: translate(-50%, -50%); }

@-webkit-keyframes move {
  0% {
    background-position: 0 0; }
  100% {
    background-position: 30px 0px; } }

@keyframes move {
  0% {
    background-position: 0 0; }
  100% {
    background-position: 30px 0px; } }

.input-password-wrapper {
  position: relative; }

.btn-show-password {
  position: absolute;
  right: 4rem;
  top: 13px;
  width: 20px;
  height: 20px;
  z-index: 2;
  background: url("/assets/icon-eye.png") center center;
  background-size: contain;
  background-repeat: no-repeat; }

.input-password-wrapper input[type=password] {
  font-size: 20px; }

.icon-camera-big {
  height: 120px; }

.lang-select {
  background: #ffffff;
  height: 46px;
  border-radius: 23px;
  width: 100%;
  padding: 0 2rem;
  margin: 0;
  margin-bottom: 3rem; }

.screen-inner-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  box-sizing: border-box; }

.language-selector {
  position: absolute;
  right: 3rem;
  top: 3rem;
  font-size: 16px;
  font-weight: 600;
  z-index: 2; }

.language-selector span {
  opacity: 0.6; }

.language-selector span.active {
  opacity: 1; }

.language-selector .btn:hover {
  cursor: pointer; }

.language-selector .divider {
  margin: 0 1rem; }

/* Layout */
.scroll-content {
  overflow: scroll;
  overflow-x: hidden;
  padding: 6rem 4rem; }

.flexible-center {
  display: flex;
  align-items: center;
  flex: 1 1; }

.flexible-center > div {
  margin: auto; }

.flexible-top {
  flex: 1 1;
  /* margin-top: 6rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-direction: row;
  min-height: 1px; }

.fixed-bottom {
  min-height: 1px; }

.float-button-left {
  position: absolute;
  left: 4rem;
  top: 5rem;
  z-index: 99; }

.float-button-right {
  position: absolute;
  right: 4rem;
  top: 5rem; }

.layout-overlay {
  position: absolute;
  z-index: 2;
  /*
  width: 100%;
  height: 100%;
  */
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column; }

.layout-overlay.with-padding {
  padding: 0 24px;
  box-sizing: border-box; }

/* Welcome screen */
.welcome.screen p {
  /* max-width: 280px; */ }

/*
.welcome.screen .fixed-bottom {
  width: 100%;
}
*/
/* Login screen */
.login.screen p {
  max-width: 280px; }

/* LoginEmail screen */
.login-email.screen .button-primary {
  margin-top: 3rem; }

.login-email.screen p {
  max-width: 280px; }

.login-email.screen .fixed-bottom {
  margin-bottom: 9rem; }

/* RememberCode screen */
.remember-code.screen .button-primary {
  margin-top: 3rem; }

/* SignUp screen */
.signup.screen h1 {
  max-width: 254px;
  margin-bottom: 4rem; }

.signup.screen .policy-block {
  max-width: 260px;
  margin: auto;
  margin-bottom: 6rem;
  margin-top: 4rem; }

.signup.screen .policy-block p {
  margin-bottom: 0rem;
  font-weight: 500; }
  .signup.screen .policy-block p a, .signup.screen .policy-block p a:link, .signup.screen .policy-block p a:active, .signup.screen .policy-block p a:visited, .signup.screen .policy-block p a:hover {
    text-decoration: none;
    font-weight: 700;
    color: inherit; }

.signup.screen .button-secondary-no-bg {
  margin-top: 2rem; }

.signup.screen button.inline {
  font-size: 12px;
  font-weight: 700;
  display: inline-block; }

/* SignUpConfirmation screen */
.signup-confirmation.screen p {
  max-width: 280px; }

.signup-confirmation.screen .button-secondary-no-bg {
  margin-top: 2rem; }

.signup-confirmation.screen .fixed-bottom {
  margin-bottom: 9rem; }

.signup-confirmation.screen .button-secondary-no-bg {
  /* max-width: 230px; */
  margin-left: auto;
  margin-right: auto; }

/* RequestAccessCamera screen */
.request-access-camera.screen .flexible-top {
  margin-top: 6rem; }

/* IOSDisclaimer screen */
.ios-disclaimer.screen .flexible-top {
  margin-top: 0; }

/* CameraInaccessible screen */
.incompatible-browser.screen .flexible-top {
  margin-top: 0; }

/* Thanks screen */
.thanks.screen .flexible-top {
  margin-top: 6rem; }

/* CameraCalibration screen */
.camera-calibration.screen {
  padding: 0; }

.camera-calibration.screen .flexible-top {
  margin-top: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center; }

.camera-calibration.screen .button-primary {
  max-width: 300px;
  margin: auto; }

/*
.camera-calibration.screen .fixed-bottom,
.camera-calibration.screen p {
  margin-bottom: 0;
}
*/
.camera-calibration.screen .with-padding p {
  /* max-width: 238px; */
  /* margin-bottom: 7rem; */ }

.camera-calibration.screen .with-padding p span {
  display: inline-block; }

.camera-calibration.screen .with-padding .fixed-bottom {
  margin-bottom: 12rem; }
  .camera-calibration.screen .with-padding .fixed-bottom p {
    color: #ffffff; }

.camera-calibration .fixed-bottom {
  width: 100%; }

/* Experience screen */
.experience.screen {
  background: #121212;
  padding: 0px; }

.experience .flexible-top {
  padding-bottom: 4rem;
  padding-top: 4rem;
  height: 0; }

.experience .fixed-bottom {
  padding-bottom: 2rem; }

.experience .fixed-bottom i {
  font-size: 24px; }

.experience .button-primary {
  width: calc(100% - 48px);
  margin: 0 auto 4rem; }

#playerVideo,
#playerVideoB,
#playerImage {
  display: flex;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  pointer-events: none; }

.experience.film #playerVideo {
  width: 100%;
  margin: auto; }

.experience.film #playerVideo.portrait {
  width: auto;
  height: 100%; }

/*
#playerVideo,
#playerImage {
  width: 100%;
  margin: auto;
}
*/
/*
#playerVideo.portrait,
#playerImage.portrait {
  width: auto;
  height: 100%;
}
*/
/*
.experience .flexible-top {
  align-items: flex-end;
  padding-bottom: 4rem;
}
*/
/* ExperienceMoments screen */
.experience-moments.screen {
  padding: 0px;
  /* background: #121212; */ }

.experience-moments.screen .fixed-bottom {
  flex: 1 1;
  margin-bottom: 0;
  align-items: center;
  display: flex; }

.experience-moments.screen .flexible-top {
  flex: none;
  margin-top: 3rem;
  z-index: 1; }

.experience-moments.screen .float-button-left,
.experience-moments.screen .float-button-right {
  z-index: 2; }

.experience-moments.screen .cards {
  padding-top: 8rem; }

.experience-moments.screen h1 {
  margin-bottom: 2rem; }

.experience-moments.screen .flexible-center h1 {
  max-width: 300px; }

.experience-moments.screen .fixed-bottom .loader {
  margin: auto; }

.screen-wrapper .screen.module-qa {
  overflow: auto; }
  .screen-wrapper .screen.module-qa .flexible-top {
    align-items: initial;
    margin-bottom: 0rem; }
  .screen-wrapper .screen.module-qa .experience-progress {
    margin-bottom: 4rem; }

.qa-question-wrapper {
  width: 100%;
  padding-bottom: 6rem; }
  .qa-question-wrapper h1 {
    margin-top: 10rem;
    margin-bottom: 8rem;
    font-size: 16px; }
  .qa-question-wrapper .answers {
    color: #ffffff; }
    .qa-question-wrapper .answers .answer {
      border: 1px #ffffff30 solid;
      /* background: #ffffff; */
      border-radius: 6px;
      padding: 3rem 2rem 3rem 4rem;
      margin-bottom: 3rem;
      text-align: left;
      white-space: pre-line;
      display: flex; }
      .qa-question-wrapper .answers .answer .answer-text {
        flex: 1 1; }
      .qa-question-wrapper .answers .answer .answer-icon {
        align-items: center;
        display: flex; }
        .qa-question-wrapper .answers .answer .answer-icon i {
          color: #ffffff35; }
    .qa-question-wrapper .answers .answer:hover {
      cursor: pointer;
      border: 1px #ffffff90 solid; }
      .qa-question-wrapper .answers .answer:hover .answer-icon i {
        color: #ffffff90; }

/* Home screen */
.home.screen .fixed-bottom p {
  /* max-width: 233px; */ }

.home.screen .flexible-top {
  margin-top: 20rem; }

.home.screen .float-button-right button {
  margin-left: 4rem; }

.home.screen .float-top {
  margin: 0; }

/* Options screen */
.options.screen {
  background: #121212 url("/assets/background-color.png") center center;
  background-size: cover;
  padding-left: 0;
  padding-right: 0; }

.options.screen .content {
  background: rgba(0, 0, 0, 0.2);
  padding-bottom: 8rem;
  text-align: center; }

.options.screen .input.inline:last-child input {
  border: 0; }

.options.screen .input.inline:last-child {
  border-bottom: 1px #464546 solid; }

.options.screen .button-secondary {
  margin-top: 4rem; }

.options.screen .button-secondary.red {
  color: #EE3636; }

/* Policy and conditions screen */
.policy-and-conditions.screen {
  padding: 0; }

.scroll-content p {
  margin-top: 6rem; }

.redbull .screen.splash h2 {
  display: none; }

/* Modules Recognition */
.screen.experience .flexible-top {
  box-sizing: border-box;
  max-height: 100%; }

/*
.screen.experience .camera-wrapper,
.screen.experience .camera-crosshair  {
  opacity: 1;
  transition: opacity 0.1s;
  transition-delay: 5s;
}

.screen.experience.analizing-face .camera-wrapper,
.screen.experience.analizing-face .camera-crosshair {
  opacity: 0;
  transition: opacity 0.1s;
  transition-delay: 2s;
}
*/
@-webkit-keyframes breath {
  0% {
    opacity: 1; }
  30% {
    opacity: 0.6; }
  60% {
    opacity: 1; } }
@keyframes breath {
  0% {
    opacity: 1; }
  30% {
    opacity: 0.6; }
  60% {
    opacity: 1; } }

.experience .analyzing-msg {
  position: absolute;
  top: 1rem;
  right: 1.2rem;
  width: auto;
  display: flex;
  display: none;
  align-items: center;
  color: #ff0000;
  font-size: 12px;
  font-weight: 600;
  -webkit-animation: breath;
          animation: breath;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }
  .experience .analyzing-msg .dot {
    height: 12px;
    width: 12px;
    background: #ff0000;
    border-radius: 50%;
    margin-right: 1.5rem;
    margin-top: -2px; }

.experience .video-preview {
  transform: rotateY(180deg);
  height: 100%;
  width: auto;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%); }

.experience .camera-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  /*
    top: 2rem;
    right: 1.2rem;
    */
  transition: all 0.3s;
  /* transition-delay: 3s; */
  width: auto;
  height: 150px;
  overflow: visible;
  margin-top: 0; }

.experience .camera-crosshair {
  z-index: 9;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 76px;
  height: 76px;
  opacity: 1;
  transition: opacity 0.3s;
  transition-delay: 3s; }
  .experience .camera-crosshair .TL {
    height: 3px;
    width: 12px; }
  .experience .camera-crosshair .TR {
    height: 3px;
    width: 12px; }
  .experience .camera-crosshair .RT {
    height: 9px;
    width: 3px;
    top: 3px; }
  .experience .camera-crosshair .RB {
    height: 9px;
    width: 3px;
    bottom: 3px; }
  .experience .camera-crosshair .BR {
    height: 3px;
    width: 12px; }
  .experience .camera-crosshair .BL {
    height: 3px;
    width: 12px; }
  .experience .camera-crosshair .LB {
    height: 9px;
    width: 3px;
    bottom: 3px; }
  .experience .camera-crosshair .LT {
    height: 9px;
    width: 3px;
    top: 3px; }

.experience .border-left {
  width: 2px;
  background: #2d3235;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0; }

.experience .border-right {
  width: 2px;
  background: #2d3235;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0; }

.experience .border-top {
  height: 3px;
  background: #2d3235;
  position: absolute;
  top: 0;
  left: 0;
  right: 0; }

.experience .search-face-msg {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  font-size: 12px;
  font-weight: 600;
  transition: opacity 0.3s;
  transition-delay: 3s;
  padding: 1rem 1rem 1.5rem;
  background: #2d3235;
  border-radius: 0 0 6px 6px;
  color: #a3acaf; }
  .experience .search-face-msg div {
    line-height: normal;
    line-height: initial; }

.experience.analizing-face .camera-wrapper {
  transform: translateY(-8rem);
  opacity: 0;
  transition-delay: 0s; }

.camera-calibration .layout-overlay {
  z-index: 4; }

.incompatible-device {
  display: flex;
  height: 100%;
  background: #1C1D2E;
  width: 100%; }
  .incompatible-device .incompatible-devide-wrapper {
    width: 100%;
    max-width: 300px;
    text-align: center;
    margin: auto; }
    .incompatible-device .incompatible-devide-wrapper img {
      width: 80px;
      margin-bottom: 8rem;
      margin-top: 12rem; }
    .incompatible-device .incompatible-devide-wrapper h2 {
      color: #EDEDF1;
      margin: 0;
      margin-bottom: 4rem;
      font-size: 16px;
      font-weight: 500; }
    .incompatible-device .incompatible-devide-wrapper p {
      color: #7f7d86;
      margin: 0 0 6rem;
      font-size: 15px; }

.screen-wrapper .screen.loading-resources .rai-activity-indicator {
  margin-bottom: 4rem;
  margin-top: 4rem; }

.screen-wrapper .screen.loading-resources p.small {
  font-size: 12px;
  font-weight: 600; }

.screen-wrapper .screen.loading-resources .fixed-bottom p {
  max-width: none; }

.process-warning {
  margin-top: 8rem; }
  .process-warning small {
    display: inline-block;
    margin-top: 2rem; }

.loading-resources-box {
  position: relative;
  width: 260px;
  margin: auto; }
  .loading-resources-box .loading-resources-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%; }
    .loading-resources-box .loading-resources-text p {
      margin-bottom: 0rem;
      min-height: 25px; }
    .loading-resources-box .loading-resources-text p.animated {
      font-size: 30px;
      color: #278DF4;
      letter-spacing: 2px;
      text-align: left;
      margin: auto;
      width: 23px; }

.screen.instructions {
  /* background: #1C1D2E; */
  padding: 0rem; }
  .screen.instructions .screen-inner-wrapper {
    overflow: auto;
    padding-top: 10rem; }
    .screen.instructions .screen-inner-wrapper .flexible-top {
      flex: none;
      display: inherit;
      margin-top: 0rem;
      margin-bottom: 6rem; }
      .screen.instructions .screen-inner-wrapper .flexible-top .logo-big {
        max-width: 160px; }
    .screen.instructions .screen-inner-wrapper .instructions {
      max-width: 650px;
      margin: auto; }
      .screen.instructions .screen-inner-wrapper .instructions h1 {
        /* color: #00CDEE; */ }
      .screen.instructions .screen-inner-wrapper .instructions ul {
        margin: auto;
        list-style: decimal;
        text-align: left; }
        .screen.instructions .screen-inner-wrapper .instructions ul li {
          margin-bottom: 3rem;
          padding-left: 2rem;
          font-size: 17px; }
      .screen.instructions .screen-inner-wrapper .instructions b {
        /* color: #ffffff; */
        font-weight: 600; }
    .screen.instructions .screen-inner-wrapper .policy-block {
      max-width: 290px; }
      .screen.instructions .screen-inner-wrapper .policy-block p {
        margin-left: 4rem; }
    .screen.instructions .screen-inner-wrapper .button-primary {
      /* background: #00CDEE; */ }
    .screen.instructions .screen-inner-wrapper .fixed-bottom {
      min-height: inherit;
      margin-bottom: 12rem; }

.logo-byalyze {
  position: relative;
  z-index: 9; }
  .logo-byalyze p {
    margin: 0;
    background: #ffffff;
    padding: 1rem 2rem;
    border-radius: 6px 6px 0 0;
    border: 1px #D4D6D7 solid;
    position: absolute;
    right: 0;
    top: 4rem;
    transform-origin: top right;
    transform: rotate(-90deg) translateY(-100%); }
    .logo-byalyze p a {
      font-weight: 800;
      font-size: 12px;
      text-decoration: none;
      color: #1b1e2e;
      color: #15121A; }

.create-your-own {
  margin-top: 8rem; }
  .create-your-own h2 {
    font-size: 24px;
    line-height: normal;
    font-weight: 600;
    margin-bottom: 4rem; }

.show-player-a #playerVideoB {
  display: none; }

.show-player-b #playerVideo {
  display: none; }

.experience.screen .float-player-bar {
  height: 50px;
  display: flex;
  align-items: center;
  transition: visibility 0.3s linear, opacity 0.3s linear; }
  .experience.screen .float-player-bar .progress-bar {
    background: #c5c5c596;
    height: 5px;
    margin-right: 2rem;
    flex: 1 1;
    margin-left: 4rem;
    margin-top: 2px; }
    .experience.screen .float-player-bar .progress-bar .progress {
      background: #ffffff;
      height: 100%;
      width: 20%; }
  .experience.screen .float-player-bar .progress-time {
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    text-align: left;
    margin-right: 4rem; }

.experience.screen i.icon.icon-sound-1 {
  font-size: 25rem;
  opacity: .3;
  margin: 0 auto; }

.experience.screen.film .flexible-top {
  padding: 0; }

.experience.screen.film .pause-message {
  background: #00000080;
  color: #fff;
  /* border: 1px #fff solid; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem 3rem;
  white-space: nowrap; }
  .experience.screen.film .pause-message .button-primary {
    width: auto;
    padding: 0 3rem;
    margin-top: 4rem;
    margin-bottom: 1rem;
    height: 42px;
    font-size: 12px; }

.experience.screen.film .float-player-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: black;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
  padding: 0 2rem; }
  .experience.screen.film .float-player-bar .button-play {
    display: flex;
    color: white;
    text-align: left;
    align-items: center; }
    .experience.screen.film .float-player-bar .button-play:hover {
      cursor: pointer; }
    .experience.screen.film .float-player-bar .button-play i {
      color: #ffffff; }
    .experience.screen.film .float-player-bar .button-play p {
      color: white;
      margin: 0;
      margin-left: 1rem;
      font-size: 12px;
      font-weight: 600; }

.rc-dialog-root {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  text-align: left; }
  .rc-dialog-root .recharged-credit-input {
    margin-top: 6rem; }
  .rc-dialog-root .rc-dialog.loading .main-button span {
    visibility: hidden; }
  .rc-dialog-root .rc-dialog-mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #6b8286;
    opacity: 0.8;
    z-index: 9; }
  .rc-dialog-root .rc-dialog-mask-hidden {
    display: none; }
  .rc-dialog-root .rc-dialog-content {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    background: #ffffff;
    border: 0px #D4D6D7 solid;
    height: auto;
    transform: translate(-50%, -50%);
    border-radius: 2px;
    padding: 3rem 3rem;
    box-sizing: border-box;
    min-width: 500px; }
    .rc-dialog-root .rc-dialog-content button {
      height: 42px;
      font-size: 13px; }
  .rc-dialog-root .rc-dialog.small-modal .rc-dialog-content {
    max-width: 500px; }
  .rc-dialog-root .rc-dialog.dialogInfo .rc-dialog-close {
    right: -5rem;
    top: -7rem; }
    @media only screen and (max-width: 1024px) {
      .rc-dialog-root .rc-dialog.dialogInfo .rc-dialog-close {
        right: 1rem;
        top: 0; }
        .rc-dialog-root .rc-dialog.dialogInfo .rc-dialog-close i {
          color: #D4D6D7; } }
  .rc-dialog-root .rc-dialog.dialogInfo .rc-dialog-content {
    padding-bottom: 0;
    min-width: 600px; }
    @media only screen and (max-width: 600px) {
      .rc-dialog-root .rc-dialog.dialogInfo .rc-dialog-content {
        min-width: auto; } }
  .rc-dialog-root .rc-dialog.dialogInfo .rc-dialog-body .modal-body {
    background: #ffffff;
    border-top: 1px solid #EDEEEE; }
    @media only screen and (max-width: 600px) {
      .rc-dialog-root .rc-dialog.dialogInfo .rc-dialog-body .modal-body {
        padding: 3rem; } }
    @media only screen and (max-height: 800px) {
      .rc-dialog-root .rc-dialog.dialogInfo .rc-dialog-body .modal-body {
        max-height: 70vh;
        overflow-y: scroll; } }
  @media only screen and (min-width: 1024px) {
    .rc-dialog-root .rc-dialog.dialogInfo.dialogInfoLarge .rc-dialog-content {
      min-width: 990px; } }
  .rc-dialog-root .rc-dialog-close {
    display: none;
    background: transparent;
    border: 0;
    position: absolute;
    right: -8rem;
    top: -7rem; }
    .rc-dialog-root .rc-dialog-close i {
      color: #ffffff;
      font-size: 16px; }
  .rc-dialog-root .rc-dialog-close:hover {
    cursor: pointer; }
  .rc-dialog-root .rc-dialog-close:focus {
    outline: 0;
    border: 0; }
  .rc-dialog-root .rc-dialog-title {
    color: #1C1D2E;
    font-size: 24px;
    font-weight: 300;
    margin-bottom: 4rem; }
  .rc-dialog-root .rc-dialog-body {
    color: #1C1D2E; }
    .rc-dialog-root .rc-dialog-body .modal-subtitle {
      margin-top: -3rem;
      margin-bottom: 2rem;
      font-size: 13px;
      font-weight: 400; }
    .rc-dialog-root .rc-dialog-body .modal-body {
      background: #EDEEEE;
      margin-left: -3rem;
      margin-right: -3rem;
      padding: 2rem 3rem; }
      .rc-dialog-root .rc-dialog-body .modal-body p {
        /* margin: 0; */
        font-size: 15px;
        margin-bottom: 0; }
    .rc-dialog-root .rc-dialog-body a {
      color: #00C7E7;
      text-decoration: none; }
    .rc-dialog-root .rc-dialog-body button.full-width {
      margin: 0; }
    .rc-dialog-root .rc-dialog-body .policy-block {
      margin-bottom: 2rem; }
      .rc-dialog-root .rc-dialog-body .policy-block p {
        margin: 0; }
      .rc-dialog-root .rc-dialog-body .policy-block a {
        color: #1C1D2E;
        font-weight: 500; }
    .rc-dialog-root .rc-dialog-body h2 {
      font-weight: 500;
      font-size: 16px;
      margin-top: 5rem; }
    .rc-dialog-root .rc-dialog-body .large {
      font-weight: 500;
      font-size: 16px; }
  .rc-dialog-root ul {
    margin: 0;
    padding-left: 3rem; }
    .rc-dialog-root ul li {
      margin-bottom: 1rem; }
      .rc-dialog-root ul li:last-child {
        margin-bottom: 0; }
  .rc-dialog-root .dialog-buttons {
    text-align: right;
    padding-bottom: 3rem;
    margin-top: 4rem;
    /*
    margin-right: -2rem;
    margin-left: -2rem;
    margin-bottom: -2rem;
    */ }
    .rc-dialog-root .dialog-buttons button {
      display: inline-block;
      padding: 0 3rem;
      width: auto; }
      @media only screen and (max-width: 1024px) {
        .rc-dialog-root .dialog-buttons button {
          line-height: inherit;
          padding: 0 3rem; } }
    .rc-dialog-root .dialog-buttons .inline.button-red {
      color: #EF233C; }

.user-information {
  line-height: normal;
  line-height: initial;
  color: white;
  z-index: 10;
  font-size: 12px;
  font-weight: 500;
  position: absolute;
  top: 3rem;
  left: 3rem;
  opacity: 1;
  text-align: left;
  transition: visibility 0.3s linear, opacity 0.3s linear; }

.user-information.hidden,
.float-player-bar.hidden {
  visibility: hidden;
  opacity: 0; }

.button-fullscreen {
  color: #fff;
  text-align: right;
  margin-right: 3rem; }
  .button-fullscreen:hover {
    cursor: pointer; }
  .button-fullscreen i {
    color: #fff;
    font-size: 16px !important; }
  .button-fullscreen.film {
    margin-left: 2rem;
    margin-right: 0; }
  .button-fullscreen.qa {
    position: fixed;
    bottom: 2rem;
    right: 0; }

.skip-modules-container {
  position: relative;
  z-index: 9; }
  .skip-modules-container .skip-modules {
    position: absolute;
    top: 4rem;
    left: 0;
    color: #1C1D2E;
    text-align: left;
    display: flex; }
    .skip-modules-container .skip-modules .expand-button {
      display: flex;
      align-items: center;
      background: #fff;
      padding: 1rem;
      border: 1px #D4D6D7 solid;
      border-left: none;
      z-index: 1;
      transition: border-radius 0.3s ease-in-out; }
      .skip-modules-container .skip-modules .expand-button i {
        transform: rotate(-180deg);
        transition: transform 0.3s linear; }
      .skip-modules-container .skip-modules .expand-button:hover {
        background: #EDEEEE;
        cursor: pointer; }
        .skip-modules-container .skip-modules .expand-button:hover i {
          color: #00C7E7; }
      .skip-modules-container .skip-modules .expand-button.closed {
        border-radius: 0 6px 6px 0; }
        .skip-modules-container .skip-modules .expand-button.closed i {
          transform: rotate(0deg);
          transition: transform 0.3s linear; }
    .skip-modules-container .skip-modules .module-control {
      background: #fff;
      border-radius: 0 6px 6px 0;
      border: 1px #D4D6D7 solid;
      border-left: none;
      padding: 0;
      transform: translateX(0);
      transition: all 0.3s ease-in-out; }
      .skip-modules-container .skip-modules .module-control.hidden {
        visibility: hidden;
        transform: translateX(-100%); }
      .skip-modules-container .skip-modules .module-control .module-control-form {
        display: flex;
        align-items: flex-end;
        padding: 3rem 4rem; }
        .skip-modules-container .skip-modules .module-control .module-control-form .input {
          margin: 0; }
          .skip-modules-container .skip-modules .module-control .module-control-form .input label {
            margin-bottom: 1rem;
            font-size: 12px; }
        .skip-modules-container .skip-modules .module-control .module-control-form .input-wrapper.select i {
          display: none; }
    .skip-modules-container .skip-modules .arrow-buttons {
      display: flex;
      justify-content: space-between; }
      .skip-modules-container .skip-modules .arrow-buttons .arrow-button {
        display: flex;
        flex-direction: column;
        flex: 1 1;
        text-align: center; }
        .skip-modules-container .skip-modules .arrow-buttons .arrow-button.disabled {
          opacity: .6; }
        .skip-modules-container .skip-modules .arrow-buttons .arrow-button span {
          font-size: 12px; }
        .skip-modules-container .skip-modules .arrow-buttons .arrow-button button {
          background-color: #D4D6D7;
          color: #1C1D2E;
          padding: 1rem 2rem; }
          .skip-modules-container .skip-modules .arrow-buttons .arrow-button button:hover:not([disabled]) {
            cursor: pointer;
            background: #EDEEEE; }
            .skip-modules-container .skip-modules .arrow-buttons .arrow-button button:hover:not([disabled]) i {
              color: #00C7E7; }
          .skip-modules-container .skip-modules .arrow-buttons .arrow-button button[disabled]:hover {
            cursor: default; }
        .skip-modules-container .skip-modules .arrow-buttons .arrow-button:last-of-type {
          padding-left: 1px; }
          .skip-modules-container .skip-modules .arrow-buttons .arrow-button:last-of-type button {
            border-radius: 0 0 6px 0; }

.input {
  margin-bottom: 2rem;
  position: relative;
  text-align: left; }
  .input label {
    margin-bottom: 2rem;
    display: block; }

.input:before {
  /* content: ''; */
  position: absolute;
  left: 4rem;
  top: 13px;
  width: 20px;
  height: 20px;
  z-index: 2; }

.input input {
  background: #ffffff;
  height: 46px;
  width: 100%;
  border: 0;
  border-radius: 23px;
  color: #000000;
  font-family: inherit;
  font-size: 13px;
  font-weight: 600;
  position: relative;
  overflow: hidden;
  padding: 0 2rem;
  box-sizing: border-box;
  border: 2px transparent solid;
  transition: 200ms border ease-out;
  border: 1px #EDEEEE solid; }

.input select,
.input .input-row.select-item .field .select select {
  outline: none;
  background: #ffffff;
  height: 46px;
  width: 100%;
  border: 0;
  border-radius: 23px;
  color: #000000;
  font-family: inherit;
  font-size: 13px;
  font-weight: 600;
  position: relative;
  overflow: hidden;
  padding: 0 2rem;
  box-sizing: border-box;
  border: 2px transparent solid;
  transition: 200ms border ease-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

.input .select {
  position: relative; }

.input .select i {
  position: absolute;
  right: 2rem;
  top: 13px;
  z-index: 2;
  color: #000000;
  pointer-events: none; }

.input.inline {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 4rem;
  margin: 0; }

.input.inline input {
  border-radius: 0px;
  text-align: left;
  padding: 0;
  color: #ffffff;
  background: none;
  font-size: 15px;
  font-weight: 500;
  flex: 1 1;
  border-bottom: 1px #464546 solid;
  height: 63px; }

.input.inline .input-name {
  width: 100px;
  text-align: left;
  font-size: 15px;
  font-weight: 500; }

.input input:focus,
.input .input-row.select-item .field .select select:focus-visible {
  outline: 0; }

.input input::-webkit-input-placeholder,
.input .input-row.select-item .field .select select:invalid {
  color: #717171; }

.input.inline input::-webkit-input-placeholder {
  color: #464546; }

.input.user:before {
  background: url("/assets/icon-user-dark.png") center center;
  background-size: contain;
  background-repeat: no-repeat; }

.input.email:before {
  background: url("/assets/icon-email-dark.png") center center;
  background-size: contain;
  background-repeat: no-repeat; }

.input.lock:before {
  background: url("/assets/icon-lock-dark.png") center center;
  background-size: contain;
  background-repeat: no-repeat; }

.input.invalid input,
.input.invalid select,
.input .input-row.select-item .field .select.invalid select {
  border-color: #EE3636; }

.input .input-row.select-item .field:not(:last-child) {
  margin-right: 3rem; }

.input .input-row.select-item .field .select select {
  padding: 0 6rem 0 2rem;
  border: 1px #EDEEEE solid;
  border-radius: 2px; }

.input .input-row.select-item .field .select:after {
  content: '\ecc3';
  font-family: 'sounditi-client';
  font-size: 15px;
  line-height: 46px;
  color: #000000;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 40px;
  pointer-events: none; }

.DayPickerInput {
  display: block; }

.DayPickerInput:focus {
  outline: 0; }

.DayPicker {
  font-size: 3rem;
  color: #000000; }

.button-primary {
  background: #000000;
  height: 46px;
  width: 100%;
  border: 0;
  border-radius: 23px;
  color: #ffffff;
  font-family: inherit;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center; }

.button-secondary {
  background: #000000;
  height: 36px;
  width: auto;
  border: 0;
  border-radius: 18px;
  color: #ffffff;
  font-family: inherit;
  font-size: 13px;
  font-weight: 500;
  text-transform: none;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 0 2rem;
  margin: auto; }

.button-secondary-no-bg {
  background: none;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  color: #ffffff;
  font-family: inherit;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
  height: 46px;
  width: 100%;
  border-radius: 23px;
  overflow: hidden;
  align-items: unset;
  text-transform: none; }

.button-primary:focus,
.button-secondary:focus,
.button-text:focus {
  outline: 0; }

.button-primary:disabled {
  /*
    color: #545555;
    background: #161616;
    */ }

.button-icon {
  width: 66px; }

.button-icon img {
  max-width: 26px; }

.button-text {
  flex: 1 1; }

button.inline {
  background: transparent;
  border: 0;
  outline: 0;
  padding: 2rem 1rem; }

button.inline:hover {
  cursor: pointer; }

/* Social buttons */
.button-facebook {
  background: #3C5A99;
  margin-bottom: 2rem; }

.button-google {
  background: #DB4437; }

/* Button animation */
.ripple {
  position: absolute;
  background: rgba(255, 255, 255, 0.25);
  border-radius: 100%;
  transform: scale(0);
  pointer-events: none; }

.ripple.show {
  -webkit-animation: ripple 300ms ease-out;
  animation: ripple 300ms ease-out; }

@-webkit-keyframes ripple {
  to {
    transform: scale(1.5);
    opacity: 0; } }

@keyframes ripple {
  to {
    transform: scale(1.5);
    opacity: 0; } }

.button-back {
  margin: 0px;
  padding: 0px;
  border: 0;
  position: relative;
  align-items: center;
  background: transparent;
  margin-left: -8px; }
  .button-back i {
    color: #ffffff;
    font-size: 26px; }

.button-back:focus {
  outline: 0; }

.button-back img {
  max-width: 32px;
  pointer-events: none; }

.policy-block {
  display: flex;
  align-items: center; }

.policy-block p {
  font-size: 12px;
  line-height: 18px;
  text-align: left; }

.policy-block .check {
  display: flex;
  align-items: center; }

.policy-block button.inline {
  padding: 0; }

.container {
  display: block;
  position: relative;
  width: 18px;
  height: 18px;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
  padding: 3rem;
  top: -3rem;
  left: -3rem;
  margin: 0px;
  box-sizing: content-box; }

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: #eee;
  background: transparent;
  border: 2px #E5E5E5 solid;
  border-radius: 3px; }

.checkmark:after {
  content: "";
  position: absolute;
  display: none; }

.container input:checked ~ .checkmark:after {
  display: block; }

.container .checkmark:after {
  left: 4px;
  top: -4px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg); }

.policy-block.invalid .checkmark {
  border-color: #EE3636; }

.policy-information {
  padding-left: 3rem;
  width: 70px;
  height: 24px; }

.policy-information img {
  width: 24px;
  height: 24px; }

.camera-crosshair {
  width: 270px;
  height: 270px;
  margin: auto;
  position: relative;
  top: -5%; }

.camera-crosshair div {
  background: #ffffff50;
  position: absolute; }

.analizing-face .camera-crosshair div {
  background: #ffffff; }

.TL {
  height: 8px;
  width: 30px;
  top: 0px;
  left: 0px; }

.TR {
  height: 8px;
  width: 30px;
  top: 0px;
  right: 0px; }

.RT {
  height: 20px;
  width: 8px;
  top: 8px;
  right: 0px; }

.RB {
  height: 20px;
  width: 8px;
  bottom: 8px;
  right: 0px; }

.BR {
  height: 8px;
  width: 30px;
  bottom: 0px;
  right: 0px; }

.BL {
  height: 8px;
  width: 30px;
  bottom: 0px;
  left: 0px; }

.LB {
  height: 20px;
  width: 8px;
  bottom: 8px;
  left: 0px; }

.LT {
  height: 20px;
  width: 8px;
  top: 8px;
  left: 0px; }

.experience-progress {
  display: inline-flex; }

.experience-progress .step {
  background: rgba(255, 255, 255, 0.4);
  height: 2rem;
  width: 2rem;
  border-radius: 1rem;
  margin: 0 1rem;
  overflow: hidden;
  transition: width 300ms; }

.experience-progress .step.active {
  background: #ffffff;
  width: 6rem; }

.experience-progress .step.finished {
  background: #ffffff; }

@font-face {
  font-family: 'sounditi-client';
  src: url(/static/media/sounditi-client.52379f63.eot);
  src: url(/static/media/sounditi-client.52379f63.eot#iefix) format('embedded-opentype'),
       url(/static/media/sounditi-client.5c717fca.woff2) format('woff2'),
       url(/static/media/sounditi-client.d847d2ba.woff) format('woff'),
       url(/static/media/sounditi-client.58e911d4.ttf) format('truetype'),
       url(/static/media/sounditi-client.b26e2b72.svg#sounditi-client) format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'sounditi-client';
    src: url('../font/sounditi-client.svg?20726943#sounditi-client') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "sounditi-client";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-note-1:before { content: '\ec48'; } /* '' */
.icon-note-beamed:before { content: '\ec49'; } /* '' */
.icon-music-3:before { content: '\ec4a'; } /* '' */
.icon-search-7:before { content: '\ec4b'; } /* '' */
.icon-flashlight:before { content: '\ec4c'; } /* '' */
.icon-mail-7:before { content: '\ec4d'; } /* '' */
.icon-heart-7:before { content: '\ec4e'; } /* '' */
.icon-heart-empty-4:before { content: '\ec4f'; } /* '' */
.icon-star-7:before { content: '\ec50'; } /* '' */
.icon-star-empty-3:before { content: '\ec51'; } /* '' */
.icon-user-7:before { content: '\ec52'; } /* '' */
.icon-users-2:before { content: '\ec53'; } /* '' */
.icon-user-add:before { content: '\ec54'; } /* '' */
.icon-video-4:before { content: '\ec55'; } /* '' */
.icon-picture-4:before { content: '\ec56'; } /* '' */
.icon-camera-6:before { content: '\ec57'; } /* '' */
.icon-layout:before { content: '\ec58'; } /* '' */
.icon-menu-2:before { content: '\ec59'; } /* '' */
.icon-check-2:before { content: '\ec5a'; } /* '' */
.icon-cancel-6:before { content: '\ec5b'; } /* '' */
.icon-cancel-circled-3:before { content: '\ec5c'; } /* '' */
.icon-cancel-squared:before { content: '\ec5d'; } /* '' */
.icon-plus-5:before { content: '\ec5e'; } /* '' */
.icon-plus-circled-2:before { content: '\ec5f'; } /* '' */
.icon-plus-squared-1:before { content: '\ec60'; } /* '' */
.icon-minus-3:before { content: '\ec61'; } /* '' */
.icon-minus-circled-2:before { content: '\ec62'; } /* '' */
.icon-minus-squared-1:before { content: '\ec63'; } /* '' */
.icon-help-3:before { content: '\ec64'; } /* '' */
.icon-help-circled-3:before { content: '\ec65'; } /* '' */
.icon-info-3:before { content: '\ec66'; } /* '' */
.icon-info-circled-3:before { content: '\ec67'; } /* '' */
.icon-back:before { content: '\ec68'; } /* '' */
.icon-home-5:before { content: '\ec69'; } /* '' */
.icon-link-4:before { content: '\ec6a'; } /* '' */
.icon-attach-6:before { content: '\ec6b'; } /* '' */
.icon-lock-7:before { content: '\ec6c'; } /* '' */
.icon-lock-open-6:before { content: '\ec6d'; } /* '' */
.icon-eye-6:before { content: '\ec6e'; } /* '' */
.icon-tag-6:before { content: '\ec6f'; } /* '' */
.icon-bookmark-2:before { content: '\ec70'; } /* '' */
.icon-bookmarks:before { content: '\ec71'; } /* '' */
.icon-flag-2:before { content: '\ec72'; } /* '' */
.icon-thumbs-up-4:before { content: '\ec73'; } /* '' */
.icon-thumbs-down-3:before { content: '\ec74'; } /* '' */
.icon-download-5:before { content: '\ec75'; } /* '' */
.icon-upload-4:before { content: '\ec76'; } /* '' */
.icon-upload-cloud-3:before { content: '\ec77'; } /* '' */
.icon-reply-3:before { content: '\ec78'; } /* '' */
.icon-reply-all-2:before { content: '\ec79'; } /* '' */
.icon-forward-3:before { content: '\ec7a'; } /* '' */
.icon-quote-1:before { content: '\ec7b'; } /* '' */
.icon-code-2:before { content: '\ec7c'; } /* '' */
.icon-export-4:before { content: '\ec7d'; } /* '' */
.icon-pencil-6:before { content: '\ec7e'; } /* '' */
.icon-feather:before { content: '\ec7f'; } /* '' */
.icon-print-5:before { content: '\ec80'; } /* '' */
.icon-retweet-4:before { content: '\ec81'; } /* '' */
.icon-keyboard-1:before { content: '\ec82'; } /* '' */
.icon-comment-6:before { content: '\ec83'; } /* '' */
.icon-chat-5:before { content: '\ec84'; } /* '' */
.icon-bell-4:before { content: '\ec85'; } /* '' */
.icon-attention-4:before { content: '\ec86'; } /* '' */
.icon-alert:before { content: '\ec87'; } /* '' */
.icon-vcard:before { content: '\ec88'; } /* '' */
.icon-address:before { content: '\ec89'; } /* '' */
.icon-location-7:before { content: '\ec8a'; } /* '' */
.icon-map-1:before { content: '\ec8b'; } /* '' */
.icon-direction-1:before { content: '\ec8c'; } /* '' */
.icon-compass-4:before { content: '\ec8d'; } /* '' */
.icon-cup-1:before { content: '\ec8e'; } /* '' */
.icon-trash-7:before { content: '\ec8f'; } /* '' */
.icon-doc-7:before { content: '\ec90'; } /* '' */
.icon-docs-1:before { content: '\ec91'; } /* '' */
.icon-doc-landscape:before { content: '\ec92'; } /* '' */
.icon-doc-text-1:before { content: '\ec93'; } /* '' */
.icon-doc-text-inv-1:before { content: '\ec94'; } /* '' */
.icon-newspaper-2:before { content: '\ec95'; } /* '' */
.icon-book-open-1:before { content: '\ec96'; } /* '' */
.icon-book-4:before { content: '\ec97'; } /* '' */
.icon-folder-5:before { content: '\ec98'; } /* '' */
.icon-archive-1:before { content: '\ec99'; } /* '' */
.icon-box-3:before { content: '\ec9a'; } /* '' */
.icon-rss-6:before { content: '\ec9b'; } /* '' */
.icon-phone-2:before { content: '\ec9c'; } /* '' */
.icon-cog-6:before { content: '\ec9d'; } /* '' */
.icon-tools:before { content: '\ec9e'; } /* '' */
.icon-share-3:before { content: '\ec9f'; } /* '' */
.icon-shareable:before { content: '\eca0'; } /* '' */
.icon-basket-3:before { content: '\eca1'; } /* '' */
.icon-bag:before { content: '\eca2'; } /* '' */
.icon-calendar-7:before { content: '\eca3'; } /* '' */
.icon-login-3:before { content: '\eca4'; } /* '' */
.icon-logout-3:before { content: '\eca5'; } /* '' */
.icon-mic-4:before { content: '\eca6'; } /* '' */
.icon-mute-1:before { content: '\eca7'; } /* '' */
.icon-sound-1:before { content: '\eca8'; } /* '' */
.icon-volume-1:before { content: '\eca9'; } /* '' */
.icon-clock-7:before { content: '\ecaa'; } /* '' */
.icon-hourglass-5:before { content: '\ecab'; } /* '' */
.icon-lamp-1:before { content: '\ecac'; } /* '' */
.icon-light-down:before { content: '\ecad'; } /* '' */
.icon-light-up:before { content: '\ecae'; } /* '' */
.icon-adjust-2:before { content: '\ecaf'; } /* '' */
.icon-block-4:before { content: '\ecb0'; } /* '' */
.icon-resize-full-5:before { content: '\ecb1'; } /* '' */
.icon-resize-small-4:before { content: '\ecb2'; } /* '' */
.icon-popup-3:before { content: '\ecb3'; } /* '' */
.icon-publish:before { content: '\ecb4'; } /* '' */
.icon-window:before { content: '\ecb5'; } /* '' */
.icon-arrow-combo:before { content: '\ecb6'; } /* '' */
.icon-down-circled-2:before { content: '\ecb7'; } /* '' */
.icon-left-circled-2:before { content: '\ecb8'; } /* '' */
.icon-right-circled-2:before { content: '\ecb9'; } /* '' */
.icon-up-circled-2:before { content: '\ecba'; } /* '' */
.icon-down-open-3:before { content: '\ecbb'; } /* '' */
.icon-left-open-4:before { content: '\ecbc'; } /* '' */
.icon-right-open-4:before { content: '\ecbd'; } /* '' */
.icon-up-open-3:before { content: '\ecbe'; } /* '' */
.icon-down-open-mini:before { content: '\ecbf'; } /* '' */
.icon-left-open-mini:before { content: '\ecc0'; } /* '' */
.icon-right-open-mini:before { content: '\ecc1'; } /* '' */
.icon-up-open-mini:before { content: '\ecc2'; } /* '' */
.icon-down-open-big:before { content: '\ecc3'; } /* '' */
.icon-left-open-big:before { content: '\ecc4'; } /* '' */
.icon-right-open-big:before { content: '\ecc5'; } /* '' */
.icon-up-open-big:before { content: '\ecc6'; } /* '' */
.icon-down-5:before { content: '\ecc7'; } /* '' */
.icon-left-4:before { content: '\ecc8'; } /* '' */
.icon-right-4:before { content: '\ecc9'; } /* '' */
.icon-up-5:before { content: '\ecca'; } /* '' */
.icon-down-dir-3:before { content: '\eccb'; } /* '' */
.icon-left-dir-2:before { content: '\eccc'; } /* '' */
.icon-right-dir-3:before { content: '\eccd'; } /* '' */
.icon-up-dir-2:before { content: '\ecce'; } /* '' */
.icon-down-bold-1:before { content: '\eccf'; } /* '' */
.icon-left-bold-1:before { content: '\ecd0'; } /* '' */
.icon-right-bold-1:before { content: '\ecd1'; } /* '' */
.icon-up-bold-1:before { content: '\ecd2'; } /* '' */
.icon-down-thin:before { content: '\ecd3'; } /* '' */
.icon-left-thin:before { content: '\ecd4'; } /* '' */
.icon-right-thin:before { content: '\ecd5'; } /* '' */
.icon-up-thin:before { content: '\ecd6'; } /* '' */
.icon-ccw-2:before { content: '\ecd7'; } /* '' */
.icon-cw-4:before { content: '\ecd8'; } /* '' */
.icon-arrows-ccw:before { content: '\ecd9'; } /* '' */
.icon-level-down-1:before { content: '\ecda'; } /* '' */
.icon-level-up-1:before { content: '\ecdb'; } /* '' */
.icon-shuffle-3:before { content: '\ecdc'; } /* '' */
.icon-loop-1:before { content: '\ecdd'; } /* '' */
.icon-switch:before { content: '\ecde'; } /* '' */
.icon-play-4:before { content: '\ecdf'; } /* '' */
.icon-stop-5:before { content: '\ece0'; } /* '' */
.icon-pause-4:before { content: '\ece1'; } /* '' */
.icon-record-1:before { content: '\ece2'; } /* '' */
.icon-to-end-3:before { content: '\ece3'; } /* '' */
.icon-to-start-3:before { content: '\ece4'; } /* '' */
.icon-fast-forward-2:before { content: '\ece5'; } /* '' */
.icon-fast-backward-2:before { content: '\ece6'; } /* '' */
.icon-progress-8:before { content: '\ece7'; } /* '' */
.icon-progress-9:before { content: '\ece8'; } /* '' */
.icon-progress-10:before { content: '\ece9'; } /* '' */
.icon-progress-11:before { content: '\ecea'; } /* '' */
.icon-target-4:before { content: '\eceb'; } /* '' */
.icon-palette:before { content: '\ecec'; } /* '' */
.icon-list-4:before { content: '\eced'; } /* '' */
.icon-list-add:before { content: '\ecee'; } /* '' */
.icon-signal-5:before { content: '\ecef'; } /* '' */
.icon-trophy-1:before { content: '\ecf0'; } /* '' */
.icon-battery:before { content: '\ecf1'; } /* '' */
.icon-back-in-time:before { content: '\ecf2'; } /* '' */
.icon-monitor-1:before { content: '\ecf3'; } /* '' */
.icon-mobile-5:before { content: '\ecf4'; } /* '' */
.icon-network-1:before { content: '\ecf5'; } /* '' */
.icon-cd-3:before { content: '\ecf6'; } /* '' */
.icon-inbox-4:before { content: '\ecf7'; } /* '' */
.icon-install:before { content: '\ecf8'; } /* '' */
.icon-globe-5:before { content: '\ecf9'; } /* '' */
.icon-cloud-7:before { content: '\ecfa'; } /* '' */
.icon-cloud-thunder:before { content: '\ecfb'; } /* '' */
.icon-flash-3:before { content: '\ecfc'; } /* '' */
.icon-moon-3:before { content: '\ecfd'; } /* '' */
.icon-flight-2:before { content: '\ecfe'; } /* '' */
.icon-paper-plane-3:before { content: '\ecff'; } /* '' */
.icon-leaf-2:before { content: '\ed00'; } /* '' */
.icon-lifebuoy-1:before { content: '\ed01'; } /* '' */
.icon-mouse:before { content: '\ed02'; } /* '' */
.icon-briefcase-2:before { content: '\ed03'; } /* '' */
.icon-suitcase-1:before { content: '\ed04'; } /* '' */
.icon-dot:before { content: '\ed05'; } /* '' */
.icon-dot-2:before { content: '\ed06'; } /* '' */
.icon-dot-3:before { content: '\ed07'; } /* '' */
.icon-brush-2:before { content: '\ed08'; } /* '' */
.icon-magnet-2:before { content: '\ed09'; } /* '' */
.icon-infinity:before { content: '\ed0a'; } /* '' */
.icon-erase:before { content: '\ed0b'; } /* '' */
.icon-chart-pie-3:before { content: '\ed0c'; } /* '' */
.icon-chart-line-1:before { content: '\ed0d'; } /* '' */
.icon-chart-bar-4:before { content: '\ed0e'; } /* '' */
.icon-chart-area-1:before { content: '\ed0f'; } /* '' */
.icon-tape:before { content: '\ed10'; } /* '' */
.icon-graduation-cap-2:before { content: '\ed11'; } /* '' */
.icon-language-1:before { content: '\ed12'; } /* '' */
.icon-ticket-1:before { content: '\ed13'; } /* '' */
.icon-water:before { content: '\ed14'; } /* '' */
.icon-droplet:before { content: '\ed15'; } /* '' */
.icon-air:before { content: '\ed16'; } /* '' */
.icon-credit-card-3:before { content: '\ed17'; } /* '' */
.icon-floppy-1:before { content: '\ed18'; } /* '' */
.icon-clipboard-1:before { content: '\ed19'; } /* '' */
.icon-megaphone-3:before { content: '\ed1a'; } /* '' */
.icon-database-2:before { content: '\ed1b'; } /* '' */
.icon-drive:before { content: '\ed1c'; } /* '' */
.icon-bucket:before { content: '\ed1d'; } /* '' */
.icon-thermometer-1:before { content: '\ed1e'; } /* '' */
.icon-key-4:before { content: '\ed1f'; } /* '' */
.icon-flow-cascade:before { content: '\ed20'; } /* '' */
.icon-flow-branch:before { content: '\ed21'; } /* '' */
.icon-flow-tree:before { content: '\ed22'; } /* '' */
.icon-flow-line:before { content: '\ed23'; } /* '' */
.icon-flow-parallel:before { content: '\ed24'; } /* '' */
.icon-rocket-1:before { content: '\ed25'; } /* '' */
.icon-gauge-2:before { content: '\ed26'; } /* '' */
.icon-traffic-cone:before { content: '\ed27'; } /* '' */
.icon-cc-3:before { content: '\ed28'; } /* '' */
.icon-cc-by:before { content: '\ed29'; } /* '' */
.icon-cc-nc:before { content: '\ed2a'; } /* '' */
.icon-cc-nc-eu:before { content: '\ed2b'; } /* '' */
.icon-cc-nc-jp:before { content: '\ed2c'; } /* '' */
.icon-cc-sa:before { content: '\ed2d'; } /* '' */
.icon-cc-nd:before { content: '\ed2e'; } /* '' */
.icon-cc-pd:before { content: '\ed2f'; } /* '' */
.icon-cc-zero:before { content: '\ed30'; } /* '' */
.icon-cc-share:before { content: '\ed31'; } /* '' */
.icon-cc-remix:before { content: '\ed32'; } /* '' */
.icon-github-5:before { content: '\ed33'; } /* '' */
.icon-github-circled-3:before { content: '\ed34'; } /* '' */
.icon-flickr-3:before { content: '\ed35'; } /* '' */
.icon-flickr-circled:before { content: '\ed36'; } /* '' */
.icon-vimeo-4:before { content: '\ed37'; } /* '' */
.icon-vimeo-circled:before { content: '\ed38'; } /* '' */
.icon-twitter-6:before { content: '\ed39'; } /* '' */
.icon-twitter-circled-1:before { content: '\ed3a'; } /* '' */
.icon-facebook-6:before { content: '\ed3b'; } /* '' */
.icon-facebook-circled-1:before { content: '\ed3c'; } /* '' */
.icon-facebook-squared-1:before { content: '\ed3d'; } /* '' */
.icon-gplus-3:before { content: '\ed3e'; } /* '' */
.icon-gplus-circled-1:before { content: '\ed3f'; } /* '' */
.icon-pinterest-3:before { content: '\ed40'; } /* '' */
.icon-pinterest-circled-1:before { content: '\ed41'; } /* '' */
.icon-tumblr-4:before { content: '\ed42'; } /* '' */
.icon-tumblr-circled:before { content: '\ed43'; } /* '' */
.icon-linkedin-5:before { content: '\ed44'; } /* '' */
.icon-linkedin-circled-1:before { content: '\ed45'; } /* '' */
.icon-dribbble-4:before { content: '\ed46'; } /* '' */
.icon-dribbble-circled-1:before { content: '\ed47'; } /* '' */
.icon-stumbleupon-3:before { content: '\ed48'; } /* '' */
.icon-stumbleupon-circled-1:before { content: '\ed49'; } /* '' */
.icon-lastfm-3:before { content: '\ed4a'; } /* '' */
.icon-lastfm-circled:before { content: '\ed4b'; } /* '' */
.icon-rdio:before { content: '\ed4c'; } /* '' */
.icon-rdio-circled:before { content: '\ed4d'; } /* '' */
.icon-spotify-2:before { content: '\ed4e'; } /* '' */
.icon-spotify-circled:before { content: '\ed4f'; } /* '' */
.icon-qq-1:before { content: '\ed50'; } /* '' */
.icon-instagram-5:before { content: '\f32d'; } /* '' */
.icon-dropbox-2:before { content: '\f330'; } /* '' */
.icon-evernote-1:before { content: '\f333'; } /* '' */
.icon-flattr-1:before { content: '\f336'; } /* '' */
.icon-skype-5:before { content: '\f339'; } /* '' */
.icon-skype-circled:before { content: '\f33a'; } /* '' */
.icon-renren-1:before { content: '\f33c'; } /* '' */
.icon-sina-weibo:before { content: '\f33f'; } /* '' */
.icon-paypal-2:before { content: '\f342'; } /* '' */
.icon-picasa-2:before { content: '\f345'; } /* '' */
.icon-soundcloud-3:before { content: '\f348'; } /* '' */
.icon-mixi:before { content: '\f34b'; } /* '' */
.icon-behance-2:before { content: '\f34e'; } /* '' */
.icon-google-circles:before { content: '\f351'; } /* '' */
.icon-vkontakte-2:before { content: '\f354'; } /* '' */
.icon-smashing:before { content: '\f357'; } /* '' */
.icon-db-shape:before { content: '\f600'; } /* '' */
.icon-sweden:before { content: '\f601'; } /* '' */
.icon-logo-db:before { content: '\f603'; } /* '' */
.none-enter {
  z-index: 1;
}
.none-exit {
  z-index: 0;
  display: none;
}
.none-enter-done {
  z-index: 1;
}

.slide-in-enter {
  z-index: 1;
  transform: translateX(100%);
}
.slide-in-enter-active {
  z-index: 1;
  transform: translateX(0%);
}
.slide-in-enter-done {
  z-index: 1;
}
.slide-in-exit {
  z-index: -1;
  transform: translateX(0%);
}
.slide-in-exit-active {
  z-index: -1;
  transform: translateX(-40%);
}


.slide-out-enter {
  z-index: -1;
  transform: translateX(-40%);
}
.slide-out-enter-active {
  z-index: -1;
  transform: translateX(0%);
}
.slide-out-enter-done {
  z-index: 1;
}
.slide-out-exit {
  z-index: 1;
  transform: translateX(0%);
}
.slide-out-exit-active {
  z-index: 1;
  transform: translateX(100%);
}

.no-transition-exit-active {
  z-index: -1;
}

.slide-out-exit-active,
.slide-out-enter-active,
.slide-in-exit-active,
.slide-in-enter-active {
  transition: all 350ms ease-in-out;
}
@media only screen and (min-width: 1200px) {
  body {
    font-size: 17px;
    line-height: 25px;
  }

  h1 {
    font-size: 21px;
    line-height: 28px;
  }

  .button-primary {
    height: 52px;
    border-radius: 26px;
    font-size: 15px;
  }

  .policy-block p {
    font-size: 11px;
  }

  .signup.screen button.inline {
    font-size: 11px;
  }

  .signup.screen .policy-block {
    margin-top: 3rem;
  }
}

@media only screen and (min-width: 1000px) {
  .screen {
    overflow: hidden;
  }

  .screen-wrapper {
    /* width: 500px; */
    background: #121212;
  }

  .flexible-top {
    margin-bottom: 10rem;
  }

  .float-top {
    margin-bottom: 10rem;
  }

  /*
  .desktop-cover {
    display: flex;
    background: #121212 url('/assets/bg-woman.jpg') center center;
    background-size: cover;
  }

  .desktop-cover.alternative-bg {
    background: #121212 url('/assets/bg-man.jpg') center center;
    background-size: cover;
  }
  */

  .signup.screen button.inline {
    font-size: 10px;
  }

  .screen-inner-wrapper {
    height: auto;
  }

  .flexible-top {
    margin-bottom: 10rem;
  }

  .float-top {
    margin-bottom: 10rem;
  }
/*
  #playerVideo,
  #playerImage {
    width: auto;
    height: 100%;
  }

  #playerVideo,
  #playerImage {
    width: auto;
    height: 99%;
  }

  #playerVideo.portrait,
  #playerImage.portrait {
    width: 100%;
    margin: auto;
  }
  */
}

@media only screen and (min-width: 700px) and (max-width: 999px) {
  body {
    font-size: 20px;
    line-height: 28px;
  }

  h1 {
    font-size: 22px;
    margin-bottom: 5rem;
  }

  .button-primary {
    height: 56px;
    border-radius: 28px;
    font-size: 16px;
  }

  .policy-block p,
  .signup.screen button.inline {
    font-size: 13px;
  }

  .input input {
    height: 56px;
    border-radius: 28px;
    font-size: 16px;
  }

  .input:before {
    top: 19px;
  }

  body .fixed-bottom,
  body .flexible-top,
  body .swiper-slide div {
    width: 400px;
  }

  .btn-show-password {
    top: 16px;
    width: 26px;
    height: 26px;
  }

  .camera-bottom p {
    font-size: 22px;
    line-height: 32px;
  }

  .logo-small {
    max-width: 140px;
  }

  .logo-big {
    max-width: 200px;
  }

  body .thanks.screen .logo-small,
  body .request-access-camera.screen .logo-small {
    margin-bottom: 14rem;
  }

  .signup.screen .policy-block {
    max-width: 330px;
  }

  .camera-calibration.screen .logo-small {
    margin-top: 4rem;
  }

  body .camera-calibration.screen .fixed-bottom {
    width: 100%;
  }

  .camera-bottom p {
    max-width: 315px;
  }

  .swiper-slide {
    padding-bottom: 16rem;
  }

  .swiper-container-horizontal>.swiper-pagination-bullets {
    bottom: 36px;
  }
}

@media only screen and (min-width: 700px) {
  .screen:not(.welcome) .screen-inner-wrapper {
    height: auto;
  }

  .screen:not(.welcome):not(.camera-calibration) .flexible-top {
    /* margin-bottom: 12rem; */
  }

  .screen:not(.welcome) .float-top {
    margin-bottom: 10rem;
  }

  .thanks.screen .logo-small,
  .request-access-camera.screen .logo-small {
    margin-bottom: 10rem;
  }

  .thanks.screen .flexible-top,
  .home.screen .flexible-top {
    margin-top: 0rem;
  }

  .thanks.screen .fixed-bottom,
  .home.screen .fixed-bottom {
    margin-bottom: 0;
  }

  .fullwidth .desktop-cover {
    left: 100%;
  }

  .fullwidth .screen-wrapper {
    width: 100%;
  }

  .fullwidth .screen:not(.welcome) .flexible-top,
  .screen.experience .flexible-top {
    flex: 1 1;
    margin-bottom: 0rem;
  }

  .screen.iberia .logo-small {
    margin-bottom: 10rem;
  }
}

@media only screen and (min-width: 500px) {
  .fixed-bottom,
  .flexible-top,
  .swiper-slide div {
    width: 360px;
    margin-left: auto;
    margin-right: auto;
  }

  .fullwidth .fixed-bottom,
  .fullwidth .flexible-top {
    width: 100%;
  }

  .screen {
    padding-bottom: 3%;
    padding-top: 3%;
  }
}

@media only screen and (max-height: 667px) {

  /*
  .logo-big {
    max-width: 160px;
  }

  .flexible-top {
    margin-top: 6rem !important;
  }

  .fixed-bottom {
    margin-bottom: 6rem !important;
  }

  .signup.screen .fixed-bottom,
  .signup-confirmation.screen .fixed-bottom {
    margin-bottom: 4rem !important;
  }
  */
}


@media only screen and (max-width: 699px) {
  body .screen:not(.loading-resources) .screen-inner-wrapper .flexible-top,
  .screen.instructions .screen-inner-wrapper .flexible-top {
    flex: 1 1;
  }

  body .screen.loading-resources .screen-inner-wrapper .flexible-top {
    flex: inherit;
    margin-bottom: 12rem;
  }

  body .screen.loading-resources .screen-inner-wrapper {
    height: auto;
  }

  body .logo-byalyze {
    position: fixed;
    bottom: inherit;
    top: -1px;
    right: 4rem;
  }

  body .logo-byalyze p {
    border-radius: 0 0 6px 6px;
    transform: unset;
    transform-origin: unset;
    position: relative;
  }

  body .button-fullscreen {
    display: none;
  }
}
