.input {
    margin-bottom: 2rem;
    position: relative;
    text-align: left;

    label {
        margin-bottom: 2rem;
        display: block;
    }
}

.input:before {
    /* content: ''; */
    position: absolute;
    left: 4rem;
    top: 13px;
    width: 20px;
    height: 20px;
    z-index: 2;
}

.input input {
    background: #ffffff;
    height: 46px;
    width: 100%;
    border: 0;
    border-radius: 23px;
    color: #000000;
    font-family : inherit;
    font-size: 13px;
    font-weight: 600;
    position: relative;
    overflow: hidden;
    padding: 0 2rem;
    box-sizing: border-box;
    border: 2px transparent solid;
    transition: 200ms border ease-out;
    border: 1px #EDEEEE solid;
}

.input select,
.input .input-row.select-item .field .select select {
    outline: none;
    background: #ffffff;
    height: 46px;
    width: 100%;
    border: 0;
    border-radius: 23px;
    color: #000000;
    font-family : inherit;
    font-size: 13px;
    font-weight: 600;
    position: relative;
    overflow: hidden;
    padding: 0 2rem;
    box-sizing: border-box;
    border: 2px transparent solid;
    transition: 200ms border ease-out;

    -webkit-appearance:none;
    -moz-appearance:none;
    appearance:none;
}

.input .select {
    position: relative;
}

.input .select i {
    position: absolute;
    right: 2rem;
    top: 13px;
    z-index: 2;
    color: #000000;
    pointer-events: none;
}

.input.inline {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 4rem;
    margin: 0;
}

.input.inline input {
    border-radius: 0px;
    text-align: left;
    padding: 0;
    color:#ffffff;
    background: none;
    font-size: 15px;
    font-weight: 500;
    flex: 1;
    border-bottom: 1px #464546 solid;
    height: 63px;
}

.input.inline .input-name {
    width: 100px;
    text-align: left;
    font-size: 15px;
    font-weight: 500;
}

.input input:focus,
.input .input-row.select-item .field .select select:focus-visible {
    outline: 0;
}

.input input::-webkit-input-placeholder,
.input .input-row.select-item .field .select select:invalid {
    color: #717171;
}

.input.inline input::-webkit-input-placeholder {
    color: #464546;
}

.input.user:before {
    background: url('/assets/icon-user-dark.png') center center;
    background-size: contain;
    background-repeat: no-repeat;
}

.input.email:before {
    background: url('/assets/icon-email-dark.png') center center;
    background-size: contain;
    background-repeat: no-repeat;
}

.input.lock:before {
    background: url('/assets/icon-lock-dark.png') center center;
    background-size: contain;
    background-repeat: no-repeat;
}

.input.invalid input,
.input.invalid select,
.input .input-row.select-item .field .select.invalid select {
    border-color: #EE3636;
}

.input .input-row.select-item .field {

    &:not(:last-child) {
        margin-right: 3rem;
    }

    .select select {
        padding: 0 6rem 0 2rem;
        border: 1px #EDEEEE solid;
        border-radius: 2px;
    }

    .select:after {
        content: '\ecc3';
        font-family: 'sounditi-client';
        font-size: 15px;
        line-height: 46px;
        color: #000000;
        text-align: center;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 40px;
        pointer-events: none;
    }
}

.DayPickerInput {
    display: block;
}

.DayPickerInput:focus {
    outline: 0;
}

.DayPicker {
    font-size: 3rem;
    color: #000000;
}