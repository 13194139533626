@font-face {
  font-family: 'sounditi-client';
  src: url('../font/sounditi-client.eot?20726943');
  src: url('../font/sounditi-client.eot?20726943#iefix') format('embedded-opentype'),
       url('../font/sounditi-client.woff2?20726943') format('woff2'),
       url('../font/sounditi-client.woff?20726943') format('woff'),
       url('../font/sounditi-client.ttf?20726943') format('truetype'),
       url('../font/sounditi-client.svg?20726943#sounditi-client') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'sounditi-client';
    src: url('../font/sounditi-client.svg?20726943#sounditi-client') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "sounditi-client";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-note-1:before { content: '\ec48'; } /* '' */
.icon-note-beamed:before { content: '\ec49'; } /* '' */
.icon-music-3:before { content: '\ec4a'; } /* '' */
.icon-search-7:before { content: '\ec4b'; } /* '' */
.icon-flashlight:before { content: '\ec4c'; } /* '' */
.icon-mail-7:before { content: '\ec4d'; } /* '' */
.icon-heart-7:before { content: '\ec4e'; } /* '' */
.icon-heart-empty-4:before { content: '\ec4f'; } /* '' */
.icon-star-7:before { content: '\ec50'; } /* '' */
.icon-star-empty-3:before { content: '\ec51'; } /* '' */
.icon-user-7:before { content: '\ec52'; } /* '' */
.icon-users-2:before { content: '\ec53'; } /* '' */
.icon-user-add:before { content: '\ec54'; } /* '' */
.icon-video-4:before { content: '\ec55'; } /* '' */
.icon-picture-4:before { content: '\ec56'; } /* '' */
.icon-camera-6:before { content: '\ec57'; } /* '' */
.icon-layout:before { content: '\ec58'; } /* '' */
.icon-menu-2:before { content: '\ec59'; } /* '' */
.icon-check-2:before { content: '\ec5a'; } /* '' */
.icon-cancel-6:before { content: '\ec5b'; } /* '' */
.icon-cancel-circled-3:before { content: '\ec5c'; } /* '' */
.icon-cancel-squared:before { content: '\ec5d'; } /* '' */
.icon-plus-5:before { content: '\ec5e'; } /* '' */
.icon-plus-circled-2:before { content: '\ec5f'; } /* '' */
.icon-plus-squared-1:before { content: '\ec60'; } /* '' */
.icon-minus-3:before { content: '\ec61'; } /* '' */
.icon-minus-circled-2:before { content: '\ec62'; } /* '' */
.icon-minus-squared-1:before { content: '\ec63'; } /* '' */
.icon-help-3:before { content: '\ec64'; } /* '' */
.icon-help-circled-3:before { content: '\ec65'; } /* '' */
.icon-info-3:before { content: '\ec66'; } /* '' */
.icon-info-circled-3:before { content: '\ec67'; } /* '' */
.icon-back:before { content: '\ec68'; } /* '' */
.icon-home-5:before { content: '\ec69'; } /* '' */
.icon-link-4:before { content: '\ec6a'; } /* '' */
.icon-attach-6:before { content: '\ec6b'; } /* '' */
.icon-lock-7:before { content: '\ec6c'; } /* '' */
.icon-lock-open-6:before { content: '\ec6d'; } /* '' */
.icon-eye-6:before { content: '\ec6e'; } /* '' */
.icon-tag-6:before { content: '\ec6f'; } /* '' */
.icon-bookmark-2:before { content: '\ec70'; } /* '' */
.icon-bookmarks:before { content: '\ec71'; } /* '' */
.icon-flag-2:before { content: '\ec72'; } /* '' */
.icon-thumbs-up-4:before { content: '\ec73'; } /* '' */
.icon-thumbs-down-3:before { content: '\ec74'; } /* '' */
.icon-download-5:before { content: '\ec75'; } /* '' */
.icon-upload-4:before { content: '\ec76'; } /* '' */
.icon-upload-cloud-3:before { content: '\ec77'; } /* '' */
.icon-reply-3:before { content: '\ec78'; } /* '' */
.icon-reply-all-2:before { content: '\ec79'; } /* '' */
.icon-forward-3:before { content: '\ec7a'; } /* '' */
.icon-quote-1:before { content: '\ec7b'; } /* '' */
.icon-code-2:before { content: '\ec7c'; } /* '' */
.icon-export-4:before { content: '\ec7d'; } /* '' */
.icon-pencil-6:before { content: '\ec7e'; } /* '' */
.icon-feather:before { content: '\ec7f'; } /* '' */
.icon-print-5:before { content: '\ec80'; } /* '' */
.icon-retweet-4:before { content: '\ec81'; } /* '' */
.icon-keyboard-1:before { content: '\ec82'; } /* '' */
.icon-comment-6:before { content: '\ec83'; } /* '' */
.icon-chat-5:before { content: '\ec84'; } /* '' */
.icon-bell-4:before { content: '\ec85'; } /* '' */
.icon-attention-4:before { content: '\ec86'; } /* '' */
.icon-alert:before { content: '\ec87'; } /* '' */
.icon-vcard:before { content: '\ec88'; } /* '' */
.icon-address:before { content: '\ec89'; } /* '' */
.icon-location-7:before { content: '\ec8a'; } /* '' */
.icon-map-1:before { content: '\ec8b'; } /* '' */
.icon-direction-1:before { content: '\ec8c'; } /* '' */
.icon-compass-4:before { content: '\ec8d'; } /* '' */
.icon-cup-1:before { content: '\ec8e'; } /* '' */
.icon-trash-7:before { content: '\ec8f'; } /* '' */
.icon-doc-7:before { content: '\ec90'; } /* '' */
.icon-docs-1:before { content: '\ec91'; } /* '' */
.icon-doc-landscape:before { content: '\ec92'; } /* '' */
.icon-doc-text-1:before { content: '\ec93'; } /* '' */
.icon-doc-text-inv-1:before { content: '\ec94'; } /* '' */
.icon-newspaper-2:before { content: '\ec95'; } /* '' */
.icon-book-open-1:before { content: '\ec96'; } /* '' */
.icon-book-4:before { content: '\ec97'; } /* '' */
.icon-folder-5:before { content: '\ec98'; } /* '' */
.icon-archive-1:before { content: '\ec99'; } /* '' */
.icon-box-3:before { content: '\ec9a'; } /* '' */
.icon-rss-6:before { content: '\ec9b'; } /* '' */
.icon-phone-2:before { content: '\ec9c'; } /* '' */
.icon-cog-6:before { content: '\ec9d'; } /* '' */
.icon-tools:before { content: '\ec9e'; } /* '' */
.icon-share-3:before { content: '\ec9f'; } /* '' */
.icon-shareable:before { content: '\eca0'; } /* '' */
.icon-basket-3:before { content: '\eca1'; } /* '' */
.icon-bag:before { content: '\eca2'; } /* '' */
.icon-calendar-7:before { content: '\eca3'; } /* '' */
.icon-login-3:before { content: '\eca4'; } /* '' */
.icon-logout-3:before { content: '\eca5'; } /* '' */
.icon-mic-4:before { content: '\eca6'; } /* '' */
.icon-mute-1:before { content: '\eca7'; } /* '' */
.icon-sound-1:before { content: '\eca8'; } /* '' */
.icon-volume-1:before { content: '\eca9'; } /* '' */
.icon-clock-7:before { content: '\ecaa'; } /* '' */
.icon-hourglass-5:before { content: '\ecab'; } /* '' */
.icon-lamp-1:before { content: '\ecac'; } /* '' */
.icon-light-down:before { content: '\ecad'; } /* '' */
.icon-light-up:before { content: '\ecae'; } /* '' */
.icon-adjust-2:before { content: '\ecaf'; } /* '' */
.icon-block-4:before { content: '\ecb0'; } /* '' */
.icon-resize-full-5:before { content: '\ecb1'; } /* '' */
.icon-resize-small-4:before { content: '\ecb2'; } /* '' */
.icon-popup-3:before { content: '\ecb3'; } /* '' */
.icon-publish:before { content: '\ecb4'; } /* '' */
.icon-window:before { content: '\ecb5'; } /* '' */
.icon-arrow-combo:before { content: '\ecb6'; } /* '' */
.icon-down-circled-2:before { content: '\ecb7'; } /* '' */
.icon-left-circled-2:before { content: '\ecb8'; } /* '' */
.icon-right-circled-2:before { content: '\ecb9'; } /* '' */
.icon-up-circled-2:before { content: '\ecba'; } /* '' */
.icon-down-open-3:before { content: '\ecbb'; } /* '' */
.icon-left-open-4:before { content: '\ecbc'; } /* '' */
.icon-right-open-4:before { content: '\ecbd'; } /* '' */
.icon-up-open-3:before { content: '\ecbe'; } /* '' */
.icon-down-open-mini:before { content: '\ecbf'; } /* '' */
.icon-left-open-mini:before { content: '\ecc0'; } /* '' */
.icon-right-open-mini:before { content: '\ecc1'; } /* '' */
.icon-up-open-mini:before { content: '\ecc2'; } /* '' */
.icon-down-open-big:before { content: '\ecc3'; } /* '' */
.icon-left-open-big:before { content: '\ecc4'; } /* '' */
.icon-right-open-big:before { content: '\ecc5'; } /* '' */
.icon-up-open-big:before { content: '\ecc6'; } /* '' */
.icon-down-5:before { content: '\ecc7'; } /* '' */
.icon-left-4:before { content: '\ecc8'; } /* '' */
.icon-right-4:before { content: '\ecc9'; } /* '' */
.icon-up-5:before { content: '\ecca'; } /* '' */
.icon-down-dir-3:before { content: '\eccb'; } /* '' */
.icon-left-dir-2:before { content: '\eccc'; } /* '' */
.icon-right-dir-3:before { content: '\eccd'; } /* '' */
.icon-up-dir-2:before { content: '\ecce'; } /* '' */
.icon-down-bold-1:before { content: '\eccf'; } /* '' */
.icon-left-bold-1:before { content: '\ecd0'; } /* '' */
.icon-right-bold-1:before { content: '\ecd1'; } /* '' */
.icon-up-bold-1:before { content: '\ecd2'; } /* '' */
.icon-down-thin:before { content: '\ecd3'; } /* '' */
.icon-left-thin:before { content: '\ecd4'; } /* '' */
.icon-right-thin:before { content: '\ecd5'; } /* '' */
.icon-up-thin:before { content: '\ecd6'; } /* '' */
.icon-ccw-2:before { content: '\ecd7'; } /* '' */
.icon-cw-4:before { content: '\ecd8'; } /* '' */
.icon-arrows-ccw:before { content: '\ecd9'; } /* '' */
.icon-level-down-1:before { content: '\ecda'; } /* '' */
.icon-level-up-1:before { content: '\ecdb'; } /* '' */
.icon-shuffle-3:before { content: '\ecdc'; } /* '' */
.icon-loop-1:before { content: '\ecdd'; } /* '' */
.icon-switch:before { content: '\ecde'; } /* '' */
.icon-play-4:before { content: '\ecdf'; } /* '' */
.icon-stop-5:before { content: '\ece0'; } /* '' */
.icon-pause-4:before { content: '\ece1'; } /* '' */
.icon-record-1:before { content: '\ece2'; } /* '' */
.icon-to-end-3:before { content: '\ece3'; } /* '' */
.icon-to-start-3:before { content: '\ece4'; } /* '' */
.icon-fast-forward-2:before { content: '\ece5'; } /* '' */
.icon-fast-backward-2:before { content: '\ece6'; } /* '' */
.icon-progress-8:before { content: '\ece7'; } /* '' */
.icon-progress-9:before { content: '\ece8'; } /* '' */
.icon-progress-10:before { content: '\ece9'; } /* '' */
.icon-progress-11:before { content: '\ecea'; } /* '' */
.icon-target-4:before { content: '\eceb'; } /* '' */
.icon-palette:before { content: '\ecec'; } /* '' */
.icon-list-4:before { content: '\eced'; } /* '' */
.icon-list-add:before { content: '\ecee'; } /* '' */
.icon-signal-5:before { content: '\ecef'; } /* '' */
.icon-trophy-1:before { content: '\ecf0'; } /* '' */
.icon-battery:before { content: '\ecf1'; } /* '' */
.icon-back-in-time:before { content: '\ecf2'; } /* '' */
.icon-monitor-1:before { content: '\ecf3'; } /* '' */
.icon-mobile-5:before { content: '\ecf4'; } /* '' */
.icon-network-1:before { content: '\ecf5'; } /* '' */
.icon-cd-3:before { content: '\ecf6'; } /* '' */
.icon-inbox-4:before { content: '\ecf7'; } /* '' */
.icon-install:before { content: '\ecf8'; } /* '' */
.icon-globe-5:before { content: '\ecf9'; } /* '' */
.icon-cloud-7:before { content: '\ecfa'; } /* '' */
.icon-cloud-thunder:before { content: '\ecfb'; } /* '' */
.icon-flash-3:before { content: '\ecfc'; } /* '' */
.icon-moon-3:before { content: '\ecfd'; } /* '' */
.icon-flight-2:before { content: '\ecfe'; } /* '' */
.icon-paper-plane-3:before { content: '\ecff'; } /* '' */
.icon-leaf-2:before { content: '\ed00'; } /* '' */
.icon-lifebuoy-1:before { content: '\ed01'; } /* '' */
.icon-mouse:before { content: '\ed02'; } /* '' */
.icon-briefcase-2:before { content: '\ed03'; } /* '' */
.icon-suitcase-1:before { content: '\ed04'; } /* '' */
.icon-dot:before { content: '\ed05'; } /* '' */
.icon-dot-2:before { content: '\ed06'; } /* '' */
.icon-dot-3:before { content: '\ed07'; } /* '' */
.icon-brush-2:before { content: '\ed08'; } /* '' */
.icon-magnet-2:before { content: '\ed09'; } /* '' */
.icon-infinity:before { content: '\ed0a'; } /* '' */
.icon-erase:before { content: '\ed0b'; } /* '' */
.icon-chart-pie-3:before { content: '\ed0c'; } /* '' */
.icon-chart-line-1:before { content: '\ed0d'; } /* '' */
.icon-chart-bar-4:before { content: '\ed0e'; } /* '' */
.icon-chart-area-1:before { content: '\ed0f'; } /* '' */
.icon-tape:before { content: '\ed10'; } /* '' */
.icon-graduation-cap-2:before { content: '\ed11'; } /* '' */
.icon-language-1:before { content: '\ed12'; } /* '' */
.icon-ticket-1:before { content: '\ed13'; } /* '' */
.icon-water:before { content: '\ed14'; } /* '' */
.icon-droplet:before { content: '\ed15'; } /* '' */
.icon-air:before { content: '\ed16'; } /* '' */
.icon-credit-card-3:before { content: '\ed17'; } /* '' */
.icon-floppy-1:before { content: '\ed18'; } /* '' */
.icon-clipboard-1:before { content: '\ed19'; } /* '' */
.icon-megaphone-3:before { content: '\ed1a'; } /* '' */
.icon-database-2:before { content: '\ed1b'; } /* '' */
.icon-drive:before { content: '\ed1c'; } /* '' */
.icon-bucket:before { content: '\ed1d'; } /* '' */
.icon-thermometer-1:before { content: '\ed1e'; } /* '' */
.icon-key-4:before { content: '\ed1f'; } /* '' */
.icon-flow-cascade:before { content: '\ed20'; } /* '' */
.icon-flow-branch:before { content: '\ed21'; } /* '' */
.icon-flow-tree:before { content: '\ed22'; } /* '' */
.icon-flow-line:before { content: '\ed23'; } /* '' */
.icon-flow-parallel:before { content: '\ed24'; } /* '' */
.icon-rocket-1:before { content: '\ed25'; } /* '' */
.icon-gauge-2:before { content: '\ed26'; } /* '' */
.icon-traffic-cone:before { content: '\ed27'; } /* '' */
.icon-cc-3:before { content: '\ed28'; } /* '' */
.icon-cc-by:before { content: '\ed29'; } /* '' */
.icon-cc-nc:before { content: '\ed2a'; } /* '' */
.icon-cc-nc-eu:before { content: '\ed2b'; } /* '' */
.icon-cc-nc-jp:before { content: '\ed2c'; } /* '' */
.icon-cc-sa:before { content: '\ed2d'; } /* '' */
.icon-cc-nd:before { content: '\ed2e'; } /* '' */
.icon-cc-pd:before { content: '\ed2f'; } /* '' */
.icon-cc-zero:before { content: '\ed30'; } /* '' */
.icon-cc-share:before { content: '\ed31'; } /* '' */
.icon-cc-remix:before { content: '\ed32'; } /* '' */
.icon-github-5:before { content: '\ed33'; } /* '' */
.icon-github-circled-3:before { content: '\ed34'; } /* '' */
.icon-flickr-3:before { content: '\ed35'; } /* '' */
.icon-flickr-circled:before { content: '\ed36'; } /* '' */
.icon-vimeo-4:before { content: '\ed37'; } /* '' */
.icon-vimeo-circled:before { content: '\ed38'; } /* '' */
.icon-twitter-6:before { content: '\ed39'; } /* '' */
.icon-twitter-circled-1:before { content: '\ed3a'; } /* '' */
.icon-facebook-6:before { content: '\ed3b'; } /* '' */
.icon-facebook-circled-1:before { content: '\ed3c'; } /* '' */
.icon-facebook-squared-1:before { content: '\ed3d'; } /* '' */
.icon-gplus-3:before { content: '\ed3e'; } /* '' */
.icon-gplus-circled-1:before { content: '\ed3f'; } /* '' */
.icon-pinterest-3:before { content: '\ed40'; } /* '' */
.icon-pinterest-circled-1:before { content: '\ed41'; } /* '' */
.icon-tumblr-4:before { content: '\ed42'; } /* '' */
.icon-tumblr-circled:before { content: '\ed43'; } /* '' */
.icon-linkedin-5:before { content: '\ed44'; } /* '' */
.icon-linkedin-circled-1:before { content: '\ed45'; } /* '' */
.icon-dribbble-4:before { content: '\ed46'; } /* '' */
.icon-dribbble-circled-1:before { content: '\ed47'; } /* '' */
.icon-stumbleupon-3:before { content: '\ed48'; } /* '' */
.icon-stumbleupon-circled-1:before { content: '\ed49'; } /* '' */
.icon-lastfm-3:before { content: '\ed4a'; } /* '' */
.icon-lastfm-circled:before { content: '\ed4b'; } /* '' */
.icon-rdio:before { content: '\ed4c'; } /* '' */
.icon-rdio-circled:before { content: '\ed4d'; } /* '' */
.icon-spotify-2:before { content: '\ed4e'; } /* '' */
.icon-spotify-circled:before { content: '\ed4f'; } /* '' */
.icon-qq-1:before { content: '\ed50'; } /* '' */
.icon-instagram-5:before { content: '\f32d'; } /* '' */
.icon-dropbox-2:before { content: '\f330'; } /* '' */
.icon-evernote-1:before { content: '\f333'; } /* '' */
.icon-flattr-1:before { content: '\f336'; } /* '' */
.icon-skype-5:before { content: '\f339'; } /* '' */
.icon-skype-circled:before { content: '\f33a'; } /* '' */
.icon-renren-1:before { content: '\f33c'; } /* '' */
.icon-sina-weibo:before { content: '\f33f'; } /* '' */
.icon-paypal-2:before { content: '\f342'; } /* '' */
.icon-picasa-2:before { content: '\f345'; } /* '' */
.icon-soundcloud-3:before { content: '\f348'; } /* '' */
.icon-mixi:before { content: '\f34b'; } /* '' */
.icon-behance-2:before { content: '\f34e'; } /* '' */
.icon-google-circles:before { content: '\f351'; } /* '' */
.icon-vkontakte-2:before { content: '\f354'; } /* '' */
.icon-smashing:before { content: '\f357'; } /* '' */
.icon-db-shape:before { content: '\f600'; } /* '' */
.icon-sweden:before { content: '\f601'; } /* '' */
.icon-logo-db:before { content: '\f603'; } /* '' */