.policy-block {
    display: flex;
    align-items: center;
}

.policy-block p {
    font-size: 12px;
    line-height: 18px;
    text-align: left;
}

.policy-block .check {
    display: flex;
    align-items: center;
}

.policy-block button.inline {
    padding: 0;
}

.container {
    display: block;
    position: relative;
    width: 18px;
    height: 18px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 100%;
    width: 100%;
    padding: 3rem;
    top: -3rem;
    left: -3rem;
    margin: 0px;
    box-sizing: content-box;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 14px;
    width: 14px;
    background-color: #eee;
    background: transparent;
    border: 2px #E5E5E5 solid;
    border-radius: 3px;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.container input:checked ~ .checkmark:after {
    display: block;
}

.container .checkmark:after {
    left: 4px;
    top: -4px;
    width: 7px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

.policy-block.invalid .checkmark {
    border-color: #EE3636;
}

.policy-information {
    padding-left: 3rem;
    width: 70px;
    height: 24px;
}

.policy-information img {
    width: 24px;
    height: 24px;
}